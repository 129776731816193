import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomSearchBar from "components/misc/CustomSearchBar";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Button from "CustomButtons/Button.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import ShortText from "@material-ui/icons/ShortText";
import Programs from "components/lov/Programs";
import TestTypes from "components/lov/TestTypes";
import ErrorLogo from "@material-ui/icons/Error";
import testFormStyle from "styles/TestFormStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import AutoSuggestSelect from "components/misc/AutoSuggestSelect";
import TestStatus from "components/lov/TestStatus";
import TestActions from "components/test/TestActions";
import {
  formatDate,
  offsetUTCTimezone,
} from "components/helpers/DateTimeHelper.jsx";
import $ from "jquery";

const getMuiThemeForTable = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
    MUIDataTableToolbarSelect: {
      title: {
        display: "none",
      },
      root: {
        backgroundColor: "none",
        boxShadow: "none",
        justifyContent: "normal",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 16px",
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.85rem",
        height: "48px",
      },
    },
    MUIDataTableHeadCell: {
      root: {
        color: "rgba(0,0,0,0.54)",
        padding: "4px 16px",
        fontSize: "0.75rem",
        fontWeight: "500",
        height: "56px",
      },
      fixedHeader: {
        zIndex: "none",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "0px",
      },
      caption: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.75rem",
      },
      select: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.75rem",
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const getMuiThemeForSearchBar = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        border: "1px solid #aaacb1",
      },
      rounded: {
        borderRadius: "10px",
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class ManageTests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      testSearchResults: [],
      testRowSelected: "",
      testInfo: {},
      isAdvancedSearchMode: false,
      searchResultsDataForStateRetention: {},
    };
  }

  storeStateCallback() {
    sessionStorage.setItem(
      "testSearchResults",
      JSON.stringify(this.state.searchResultsDataForStateRetention)
    );
  }

  componentDidMount() {
    if (sessionStorage.testSearchResults) {
      var data = JSON.parse(sessionStorage.testSearchResults);
      if (data && data["test_info"] && data["test_info"].length > 0) {
        this.populateTestsTableFromResults(data);
        this.setState({ searchResultsDataForStateRetention: data });
        sessionStorage.removeItem("testSearchResults");
        return;
      }
    }

    var ninetyDaysInPast = new Date();
    ninetyDaysInPast = new Date(
      ninetyDaysInPast.getTime() - 90 * 24 * 60 * 60 * 1000
    ); //90 days back

    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/get-manage-tests.json?limit=20&start_time=" +
        ninetyDaysInPast,
    })
      .done((data, status, jqXHR) => {
        this.populateTestsTableFromResults(data);
        this.setState({ searchResultsDataForStateRetention: data });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message: "An Unexpected Error occured while searching for Test.",
        });
        this.setState({ errorMessages: errorMessages });
      });
  }

  handleTestSearch = (searchText) => {
    if (searchText && searchText.length > 3) {
      //Minimum three characters to search
      $.ajax({
        type: "GET",
        url:
          getBaseURL() + "/get-manage-tests.json?limit=20&name=" + searchText,
      })
        .done((data, status, jqXHR) => {
          this.populateTestsTableFromResults(data);
          this.setState({ searchResultsDataForStateRetention: data });
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          var errorMessages = this.state.errorMessages;
          errorMessages.push({
            message: "An Unexpected Error occured while searching for Test.",
          });
          this.setState({ errorMessages: errorMessages });
        });
    } else {
      this.setState({ testSearchResults: [] });
    }
  };

  populateTestsTableFromResults(data) {
    var testInfo = data["test_info"];
    var exportImportPrivilege = data["export_import_priv"];
    var testSearchResults = [];
    if (testInfo && testInfo.length > 0) {
      for (var i in testInfo) {
        testSearchResults.push([
          testInfo[i].id,
          testInfo[i].name,
          testInfo[i].identifier,
          testInfo[i]?.is_omr_test ? "OFFLINE" : "ONLINE",
          testInfo[i].status,
          testInfo[i]?.review_status ? testInfo[i]?.review_status : "PENDING",
          testInfo[i].program_short_name,
          testInfo[i].test_type_short_name,
          formatDate(offsetUTCTimezone(testInfo[i].created_at)),
          <TestActions
            name={testInfo[i].id}
            errorMessageCallback={this.errorMessageCallback}
            storeStateCallback={this.storeStateCallback.bind(this)}
            handleTestAdvSearch={this.handleTestAdvSearch}
            testStatus={testInfo[i].status}
            reviewStatus={
              testInfo[i]?.review_status
                ? testInfo[i]?.review_status
                : "PENDING"
            }
            exportImportPrivilege={exportImportPrivilege}
            history={this.props.history}
            location={this.props.location}
          />,
        ]);
      }
      this.setState({ testSearchResults });
    } else {
      this.setState({ testSearchResults: [] });
    }
    this.setState({ errorMessages: [] });
  }

  //changelisterner For scheduled test info except program and testtype lov
  handleOnChangeTestInfo = (e) => {
    e.preventDefault();
    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[1];
    var testInfo = this.state.testInfo;
    testInfo[attr] = e.target.value;
    this.setState({ testInfo });
  };

  //changelisterner for scheduled test info program and test type
  handleOnChangeLOVTestInfo(e, obj) {
    e.preventDefault();
    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[1];
    var testInfo = this.state.testInfo;
    testInfo[attr] = e.target.value;
    if (obj && obj.id) {
      //Save Id as for for LOV's
      testInfo[attr + "Id"] = obj.id;
    }
    this.setState({ testInfo });
  }

  changeSearchMode = (e) => {
    var isAdvancedSearchMode = this.state.isAdvancedSearchMode;
    this.setState({ isAdvancedSearchMode: !isAdvancedSearchMode });
  };

  handleTestAdvSearch = (e) => {
    e.preventDefault();
    var testInfo = this.state.testInfo;

    var url = getBaseURL() + "/get-manage-tests.json?limit=20";
    if (testInfo["name"]) {
      url = url + "&name=" + testInfo["name"];
    }
    if (testInfo["number"]) {
      url = url + "&identifier=" + testInfo["number"];
    }
    if (testInfo["fromDate"]) {
      url = url + "&start_time=" + testInfo["fromDate"];
    }
    if (testInfo["toDate"]) {
      url = url + "&end_time=" + testInfo["toDate"];
    }
    if (testInfo["status"]) {
      url = url + "&status=" + testInfo["status"];
    }
    if (testInfo["programId"]) {
      url = url + "&global_program_id=" + testInfo["programId"];
    }
    if (testInfo["testTypeId"]) {
      url = url + "&test_type_id=" + testInfo["testTypeId"];
    }
    if (!url.includes("&")) {
      //Default seatch
      var ninetyDaysInPast = new Date();
      ninetyDaysInPast = new Date(
        ninetyDaysInPast.getTime() - 90 * 24 * 60 * 60 * 1000
      ); //90 days back
      url = url + "&start_time=" + ninetyDaysInPast;
    }

    $.ajax({
      type: "GET",
      url: url,
    })
      .done((data, status, jqXHR) => {
        this.populateTestsTableFromResults(data);
        this.setState({ searchResultsDataForStateRetention: data });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message:
            "An Unexpected Error occured while searching for Test Model.",
        });
        this.setState({ errorMessages: errorMessages });
      });
  };

  handleTestAdvSearchReset = (e) => {
    e.preventDefault();
    var testInfo = this.state.testInfo;
    testInfo["name"] = "";
    testInfo["number"] = "";
    testInfo["fromDate"] = "";
    testInfo["toDate"] = "";
    testInfo["status"] = "";
    testInfo["program"] = "";
    testInfo["programId"] = "";
    testInfo["testType"] = "";
    testInfo["testTypeId"] = "";
    this.setState({ testInfo });
  };

  errorMessageCallback = (errorMessage) => {
    var errorMessages = this.state.errorMessages;
    errorMessages.push(errorMessage);
    this.setState({ errorMessages: errorMessages });
  };

  render() {
    const { testInfo, isAdvancedSearchMode } = this.state;
    const { classes } = this.props;
    const titleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px",
    };
    const tableTitleStyle = {
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      padding: "0px",
      margin: "0px",
      paddingTop: "10px",
    };
    const testTableOptions = {
      filter: false,
      sort: false,
      selectableRows: "none",
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      responsive: "stacked",
      expandableRows: false,
      elevation: 0,
      setRowProps: (row) => {
        return {
          style: { textAlign: "center" },
        };
      },
    };
    const testsTableColumns = [
      {
        name: "testId",
        options: {
          display: false,
        },
      },
      { name: "Name" },
      { name: "Identifier" },
      { name: "Mode" },
      { name: "Status" },
      { name: "Review" },
      { name: "Program" },
      { name: "Test Type" },
      { name: "Created At" },
      { name: "" },
    ];
    return (
      <React.Fragment>
        <GridContainer
          style={{
            backgroundColor: "white",
            paddingTop: "0px",
            paddingBottom: "20px",
            width: "100%",
            margin: "10px 0px 0px",
            marginTop: "0px",
          }}
          spacing={24}
        >
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
            data-cy="manage tests text"
          >
            <h1 style={titleStyle}>Manage Tests</h1>
            <hr />
          </GridItem>
          {this.state.errorMessages && this.state.errorMessages.length > 0 && (
            <React.Fragment>
              <GridItem
                xs={12}
                sm={12}
                md={2}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              ></GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={10}
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                {this.state.errorMessages && this.state.errorMessages.length > 0
                  ? this.state.errorMessages.map((errorMessage, index) => (
                      <div key={index}>
                        <ErrorLogo
                          className={classes.icon}
                          style={{
                            verticalAlign: "middle",
                            marginRight: "5px",
                            color: "#f44336",
                          }}
                        ></ErrorLogo>
                        <p
                          style={{
                            display: "inline-block",
                            color: "#f44336",
                            fontSize: "0.8rem",
                            overflow: "inherit",
                          }}
                          className={classes.robotoFontRegular}
                        >
                          {errorMessage["message"]}
                        </p>
                      </div>
                    ))
                  : null}
              </GridItem>
            </React.Fragment>
          )}
          <React.Fragment>
            <GridItem
              xs={12}
              sm={12}
              md={2}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            ></GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={10}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <div>
                <h3 style={subtitleStyle}>Search Tests</h3>
              </div>
            </GridItem>
            <GridItem
              style={{ backgroundColor: "#ffffff" }}
              xs={12}
              sm={12}
              md={2}
              style={{ padding: "12px" }}
            ></GridItem>
            <GridItem
              style={{ backgroundColor: "#ffffff", padding: "8px 12px" }}
              xs={12}
              sm={12}
              md={7}
            >
              <div>
                <MuiThemeProvider theme={getMuiThemeForSearchBar}>
                  <CustomSearchBar
                    inputPlaceholder={"Search Test Name"}
                    // child2={<Button style={{textTransform: "none",
                    // 					backgroundColor: "#3f51b5",
                    // 				    padding: "8px 20px",
                    // 					margin: "0px"}}>
                    //                 				Schedule Test</Button>}
                    child1={
                      <Button
                        onClick={this.changeSearchMode}
                        style={{
                          textTransform: "none",
                          backgroundColor: "#3f51b5",
                          padding: "8px 20px",
                          margin: "0px",
                        }}
                      >
                        {isAdvancedSearchMode
                          ? "Basic Search"
                          : "Advanced Search"}{" "}
                      </Button>
                    }
                    handleSearch={this.handleTestSearch}
                    hintText={"Please enter minimum 4 characters"}
                  />
                </MuiThemeProvider>
              </div>
            </GridItem>
            <GridItem
              style={{ backgroundColor: "#ffffff" }}
              xs={12}
              sm={12}
              md={3}
              style={{ padding: "12px" }}
            ></GridItem>
            {isAdvancedSearchMode && (
              <React.Fragment>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ padding: "12px" }}
                ></GridItem>
                <GridItem xs={12} sm={12} md={2}></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={3}
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  <InputLabel shrink htmlFor="material">
                    Test Name
                  </InputLabel>
                  <CustomInput
                    //labelText="Enter Test Model Name"
                    id="material"
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: "5px" },
                    }}
                    inputProps={{
                      style: { fontSize: "16px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <ShortText />
                        </InputAdornment>
                      ),
                      placeholder: "Enter Name",
                      value: testInfo["name"],
                      onChange: this.handleOnChangeTestInfo,
                      name: "testInfo_name",
                    }}
                  />
                  <InputLabel shrink htmlFor="material2">
                    Test Identifier
                  </InputLabel>
                  <CustomInput
                    //labelText="Enter Test Model Identifier"
                    id="material2"
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: "5px" },
                    }}
                    inputProps={{
                      style: { fontSize: "16px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <ShortText />
                        </InputAdornment>
                      ),
                      placeholder: "Enter Unique Key",
                      value: testInfo["number"],
                      onChange: this.handleOnChangeTestInfo,
                      name: "testInfo_number",
                    }}
                  />
                  <InputLabel shrink htmlFor="material5">
                    Tests Created From
                  </InputLabel>
                  <CustomInput
                    //labelText="Enter Section"
                    id="material5"
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: "5px" },
                    }}
                    inputProps={{
                      style: { fontSize: "16px" },
                      type: "date",
                      value: testInfo["fromDate"],
                      onChange: this.handleOnChangeTestInfo,
                      name: "testInfo_fromDate",
                    }}
                  />
                  <InputLabel shrink htmlFor="material4">
                    Tests Created Till
                  </InputLabel>
                  <CustomInput
                    //labelText="Enter Section"
                    id="material4"
                    formControlProps={{
                      fullWidth: true,
                      style: { paddingTop: "5px" },
                    }}
                    inputProps={{
                      style: { fontSize: "16px" },
                      type: "date",
                      value: testInfo["toDate"],
                      onChange: this.handleOnChangeTestInfo,
                      name: "testInfo_toDate",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <div>
                    <InputLabel shrink htmlFor="material3">
                      Status
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <TestStatus
                        name="testInfo_status"
                        formControlWidth="75%"
                        value={testInfo["status"]}
                        change={this.handleOnChangeTestInfo}
                      />
                    </div>
                    <div style={{ padding: "10px" }} />
                    <InputLabel shrink htmlFor="material2">
                      Program
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <Programs
                        name="testInfo_program"
                        formControlWidth="75%"
                        value={testInfo["program"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                    <div style={{ padding: "10px" }} />
                    <InputLabel shrink htmlFor="material2">
                      Test Type
                    </InputLabel>
                    <div style={{ paddingTop: "5px" }}>
                      <TestTypes
                        name="testInfo_testType"
                        formControlWidth="75%"
                        value={testInfo["testType"]}
                        change={this.handleOnChangeLOVTestInfo.bind(this)}
                      />
                    </div>
                    <Button
                      onClick={this.handleTestAdvSearch}
                      style={{
                        textTransform: "none",
                        backgroundColor: "#3f51b5",
                        padding: "8px 20px",
                        margin: "5px",
                        marginTop: "30px",
                      }}
                    >
                      Advanced Search
                    </Button>
                    <Button
                      onClick={this.handleTestAdvSearchReset}
                      style={{
                        textTransform: "none",
                        backgroundColor: "#3f51b5",
                        padding: "8px 20px",
                        margin: "5px",
                        marginTop: "30px",
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}></GridItem>
              </React.Fragment>
            )}
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ paddingRight: "24px", paddingLeft: "24px" }}
            >
              <MuiThemeProvider theme={getMuiThemeForTable}>
                <MUIDataTable
                  title={<p style={tableTitleStyle}>Tests</p>}
                  options={testTableOptions}
                  columns={testsTableColumns}
                  data={this.state.testSearchResults}
                />
              </MuiThemeProvider>
            </GridItem>
          </React.Fragment>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(testFormStyle)(ManageTests);
