import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import $ from "jquery";
import qs from "query-string";

import PropTypes from "prop-types";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import ShortText from "@material-ui/icons/ShortText";
import MUIDataTable from "mui-datatables";

import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import TestModelFooter from "components/testModel/TestModelFooter";
import Subjects from "components/lov/Subjects";
import QuestionPatterns from "components/lov/QuestionPatterns";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "CustomButtons/Button.jsx";

import TestModelFormStyle from "styles/TestModelFormStyle.js";

import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "components/ErrorBoundary.js";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Programs from "components/lov/Programs";
import TestTypes from "components/lov/TestTypes";
import AutoSuggestSelect from "components/misc/AutoSuggestSelect";
import WarningLogo from "@material-ui/icons/Warning";
import ErrorLogo from "@material-ui/icons/Error";

import {
  formatDate,
  offsetUTCTimezone,
} from "components/helpers/DateTimeHelper.jsx";

import { getTestPasswordEnhacement } from "components/helpers/EnhancementConfigHelper.jsx";

import TestModes from "components/lov/TestModes";

const getMuiThemeForTable = createMuiTheme({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
    MUIDataTable: {
      responsiveStacked: {
        overflow: "visible",
        overflowX: "visible",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 16px 4px 16px",
        textAlign: "center",
      },
      footer: {
        textAlign: "left",
        borderBottom: "none",
        height: "56px",
        fontWeight: "300",
      },
    },
    MUIDataTableHeadCell: {
      root: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.75rem",
        fontWeight: "500",
        lineHeight: "24px",
        height: "56px",
      },
      fixedHeader: {
        zIndex: "none",
      },
    },
    MUIDataTableBodyCell: {
      root: {
        //height: '48px'
      },
    },
    typography: {
      useNextVariants: true,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

function TestAdvSearchForm(props) {
  return (
    <React.Fragment>
      <GridContainer
        style={{
          backgroundColor: "white",
          paddingTop: "0px",
          width: "100%",
          margin: "10px 0px 0px",
          marginTop: "0px",
        }}
        spacing={24}
      >
        <GridItem xs={12} sm={12} md={6}>
          <InputLabel shrink htmlFor="material12">
            Test Name
          </InputLabel>
          <CustomInput
            //labelText="Enter Test Model Name"
            id="material12"
            formControlProps={{
              fullWidth: true,
              style: { paddingTop: "5px" },
            }}
            inputProps={{
              style: { fontSize: "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <ShortText />
                </InputAdornment>
              ),
              placeholder: "Enter Name",
              value:
                props.testAdvSearchFormData &&
                props.testAdvSearchFormData[props.rowIndex]
                  ? props.testAdvSearchFormData[props.rowIndex]["testName"]
                  : "",
              onChange: props.handleOnChangeTestAdvSearchForm,
              name: "testName_" + props.rowIndex,
            }}
          />
          <InputLabel shrink htmlFor="material13">
            Test Number
          </InputLabel>
          <CustomInput
            //labelText="Enter Test Model Identifier"
            id="material13"
            formControlProps={{
              fullWidth: true,
              style: { paddingTop: "5px" },
            }}
            inputProps={{
              style: { fontSize: "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <ShortText />
                </InputAdornment>
              ),
              placeholder: "Enter Unique Key",
              value:
                props.testAdvSearchFormData &&
                props.testAdvSearchFormData[props.rowIndex]
                  ? props.testAdvSearchFormData[props.rowIndex]["testNumber"]
                  : "",
              onChange: props.handleOnChangeTestAdvSearchForm,
              name: "testNumber" + props.rowIndex,
            }}
          />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

class ScheduleTestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduletestPapersData: [], //each JSON array child indicates row matching table elements in order
      newSectionrowIndexArray: [],
      //scheduleTestInfo: ["","",""], //three elements in order include test model name, number and description
      scheduleTestInfo: {},
      errorMessages: [],
      userGroupSelectList: [],
      userGroupAdvSearchResults: {},
      testAdvSearchResults: {},
      testSelectList: [],
      testAdvSearchFormData: {},
      isEditFlow: "",
      rescheduleTestId: "",
    };
  }

  componentDidMount() {
    const scheduled_test_id = qs.parse(
      this.props.location.search
    ).scheduled_test_id;
    const isEditFlow = qs.parse(this.props.location.search).edit;

    if (scheduled_test_id && isEditFlow) {
      //Process of duplicate only if test_id exists
      $.ajax({
        type: "GET",
        url:
          getBaseURL() +
          "/get-scheduled-test-info.json?scheduled_test_id=" +
          scheduled_test_id,
      })
        .done((data, status, jqXHR) => {
          this.loadScheduledTestData(data);
          this.setState({
            isEditFlow: isEditFlow,
            rescheduleTestId: scheduled_test_id,
          });
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          var errorMessages = this.state.errorMessages;
          errorMessages.push({
            message:
              "An unexpected error occured while trying to Re-schedule the test.",
          });
          this.setState({ errorMessages: errorMessages });
        });
    }

    //Load data for User Groups for create flow(not edit flow)
    $.ajax({
      type: "GET",
      url: getBaseURL() + "/search-user-groups.json?limit=30",
    })
      .done((data, status, jqXHR) => {
        if (data && data.length > 0) {
          var userGroupSelectList = data.map((userGroup) => ({
            label: userGroup.identifier,
            value: userGroup.id,
          }));
          this.setState({ userGroupSelectList });
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message:
            "An Unexpected Error occured while searching for Test Model.",
        });
        this.setState({ errorMessages: errorMessages });
      });
  }

  loadScheduledTestData(data) {
    var responseScheduledTestInfo = data["response"]["scheduled_test_info"][0]; //first row return scheduled test
    var scheduleTestInfo = {};
    scheduleTestInfo["name"] = responseScheduledTestInfo["name"];
    scheduleTestInfo["number"] = responseScheduledTestInfo["identifier"];
    scheduleTestInfo["description"] = responseScheduledTestInfo["description"];
    scheduleTestInfo["program"] =
      responseScheduledTestInfo["program_short_name"];
    scheduleTestInfo["programId"] = responseScheduledTestInfo["program_id"];
    scheduleTestInfo["testTypeId"] = responseScheduledTestInfo["test_type_id"];
    scheduleTestInfo["testType"] =
      responseScheduledTestInfo["test_type_short_name"];
    scheduleTestInfo["userGroupId"] =
      responseScheduledTestInfo["user_group_info_id"];
    scheduleTestInfo["userGroupName"] =
      responseScheduledTestInfo["user_group_info_name"];
    scheduleTestInfo["userGroupIdentifier"] =
      responseScheduledTestInfo["user_group_info_identifier"];
    scheduleTestInfo["testType"] = responseScheduledTestInfo["is_omr_test"]
      ? "OFFLINE"
      : "ONLINE";

    scheduleTestInfo["testMode"] = responseScheduledTestInfo["is_omr_test"];

    if (scheduleTestInfo["programId"] && scheduleTestInfo["testTypeId"]) {
      this.initTestLovData(
        scheduleTestInfo["programId"],
        scheduleTestInfo["testTypeId"]
      );
    }

    var responseScheduledTestPapers =
      data["response"]["scheduled_test_paper_info"];
    var scheduletestPapersData = [];
    for (var i in responseScheduledTestPapers) {
      var schPaperData = responseScheduledTestPapers[i];
      scheduletestPapersData.push([
        schPaperData.paper_no,
        {
          label: schPaperData.test_paper_name,
          value: schPaperData.test_info_id,
        },
        new Date(schPaperData.start_time).toISOString().substr(0, 16),
        schPaperData.end_time
          ? new Date(schPaperData.end_time).toISOString().substr(0, 16)
          : "",
        schPaperData.duration.substr(11, 5),
        schPaperData.no_of_attempts,
        schPaperData.password,
      ]);
    }
    this.setState({ scheduleTestInfo, scheduletestPapersData });
  }

  //changelisterner For scheduled test info except program and testtype lov
  handleOnChangeScheduleTestInfo = (e) => {
    e.preventDefault();
    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[1];
    var scheduleTestInfo = this.state.scheduleTestInfo;
    scheduleTestInfo[attr] = e.target.value;
    this.setState({ scheduleTestInfo });
  };

  //changelisterner for scheduled test info program and test type
  handleOnChangeLOVScheduleTestInfo(e, obj) {
    e.preventDefault();
    var testPapersSelected = false;
    var scheduletestPapersData = this.state.scheduletestPapersData;
    for (var i in scheduletestPapersData) {
      if (scheduletestPapersData[i][1]) {
        //Test papers name present
        testPapersSelected = true;
        break;
      }
    }

    if (testPapersSelected) {
      if (
        !confirm(
          "All the selected test papers will be reset. Do you still want to continue?"
        )
      ) {
        return;
      }
      for (var i in scheduletestPapersData) {
        scheduletestPapersData[i][1] = "";
      }
    }

    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[1];
    var scheduleTestInfo = this.state.scheduleTestInfo;
    scheduleTestInfo[attr] = e.target.value;
    if (obj && obj.id) {
      //Save Id as for for LOV's
      scheduleTestInfo[attr + "Id"] = obj.id;
    }
    if (scheduleTestInfo["programId"] && scheduleTestInfo["testTypeId"]) {
      this.initTestLovData(
        scheduleTestInfo["programId"],
        scheduleTestInfo["testTypeId"]
      );
    }
    this.setState({ scheduleTestInfo });
  }

  //change listener for scheduled test paper details except test info
  handleOnChangeSections = (e) => {
    e.preventDefault();
    var tableIndex = e.currentTarget.id;
    var rowIndex = tableIndex.split("_")[0];
    var columnIndex = tableIndex.split("_")[1];
    var data = this.state.scheduletestPapersData;
    data[rowIndex][columnIndex] = e.currentTarget.value;
    this.setState({
      scheduletestPapersData: data,
    });
  };

  //load test data for scheduling papers once programId and testType Id are provided
  initTestLovData(programId, testTypeId) {
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/search-tests.json?limit=30&test_type_id=" +
        testTypeId +
        "&global_program_id=" +
        programId,
    })
      .done((data, status, jqXHR) => {
        var testSelectList = data.map((test) => ({
          label: test.name,
          value: test.id,
        }));
        this.setState({ testSelectList });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message:
            "An Unexpected Error occured while searching for Test Model.",
        });
        this.setState({ errorMessages: errorMessages });
      });
  }

  handleUserGroupChange = (selectedUserGroup) => {
    var scheduleTestInfo = this.state.scheduleTestInfo;
    scheduleTestInfo["userGroupId"] = selectedUserGroup.value;
    scheduleTestInfo["userGroupIdentifier"] = selectedUserGroup.label;
    this.setState({ scheduleTestInfo });
  };

  handleUserGroupAdvSearch = (data) => {
    var scheduleTestInfo = this.state.scheduleTestInfo;
    var userGroupIdentifier = scheduleTestInfo["userGroupIdentifier"];
    var userGroupName = scheduleTestInfo["userGroupName"];
    if (!userGroupName) {
      userGroupName = "";
    }
    if (!userGroupIdentifier) {
      userGroupIdentifier = "";
    }

    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/search-user-groups.json?name=" +
        userGroupName +
        "&identifier=" +
        userGroupIdentifier +
        "&limit=30",
    })
      .done((data, status, jqXHR) => {
        this.populateUserGroupAdvSearchResults(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message:
            "An Unexpected Error occured while searching for Test Model.",
        });
        this.setState({ errorMessages: errorMessages });
      });
  };

  populateUserGroupAdvSearchResults(data) {
    var userGroupAdvSearchResults = {};
    var userGroupAdvSearchColumns = [
      {
        name: "userGroupId",
        options: {
          display: false,
        },
      },
      { name: "Name" },
      { name: "Identifier" },
      /*{name: "Description"},*/
    ];
    userGroupAdvSearchResults["columns"] = userGroupAdvSearchColumns;

    var userGroupAdvSearchData = [];
    if (data && data.length > 0) {
      for (var i in data) {
        userGroupAdvSearchData.push([
          data[i].id,
          data[i].name,
          data[i].identifier,
        ]);
      }
      userGroupAdvSearchResults["data"] = userGroupAdvSearchData;
      this.setState({ userGroupAdvSearchResults });
    } else {
      this.setState({ userGroupAdvSearchResults });
    }
  }

  //changelistener for test name component in schedule test papers data
  handleTestNameLovChangeSections = (selectedTestNameObj, actionMeta) => {
    var tableIndex = actionMeta.name;
    var rowIndex = tableIndex.split("_")[0];
    var columnIndex = tableIndex.split("_")[1];
    var scheduletestPapersData = this.state.scheduletestPapersData;
    for (var i in scheduletestPapersData) {
      if (
        scheduletestPapersData[i][1] &&
        scheduletestPapersData[i][1].value == selectedTestNameObj.value &&
        i != rowIndex
      ) {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message:
            "The schedule already has a test paper based on the selected test.",
        });
        this.setState({ errorMessages: errorMessages });
        return;
      }
    }
    scheduletestPapersData[rowIndex][columnIndex] = selectedTestNameObj;
    this.setState({ scheduletestPapersData });
  };
  //changelistener for test name component advanced search in schedule test papers data
  handleOnChangeTestAdvSearchForm = (e) => {
    e.preventDefault();
    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[0];
    var rowIndex = targetNameComp[1];
    var testAdvSearchFormData = this.state.testAdvSearchFormData;
    if (!testAdvSearchFormData[rowIndex]) {
      testAdvSearchFormData[rowIndex] = {};
    }
    testAdvSearchFormData[rowIndex][attr] = e.target.value;
    this.setState({ testAdvSearchFormData });
  };

  handleTestAdvSearch(rowIndex) {
    const testAdvSearchFormData = this.state.testAdvSearchFormData;
    var testName = testAdvSearchFormData[rowIndex]
      ? testAdvSearchFormData[rowIndex]["testName"]
      : "";
    var testNumber = testAdvSearchFormData[rowIndex]
      ? testAdvSearchFormData[rowIndex]["testNumber"]
      : "";
    const scheduleTestInfo = this.state.scheduleTestInfo;
    const programId = scheduleTestInfo["programId"];
    const testTypeId = scheduleTestInfo["testTypeId"];

    if (!testName) {
      testName = "";
    }
    if (!testNumber) {
      testNumber = "";
    }

    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/search-tests.json?limit=30&name=" +
        testName +
        "&identifier=" +
        testNumber +
        "&test_type_id=" +
        testTypeId +
        "&global_program_id=" +
        programId,
    })
      .done((data, status, jqXHR) => {
        this.populateTestAdvSearchResults(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorMessages = this.state.errorMessages;
        errorMessages.push({
          message:
            "An Unexpected Error occured while searching for Test Model.",
        });
        this.setState({ errorMessages: errorMessages });
      });
  }

  populateTestAdvSearchResults(data) {
    var testAdvSearchResults = {};
    var testAdvSearchColumns = [
      {
        name: "testId",
        options: {
          display: false,
        },
      },
      { name: "Name" },
      {
        name: "label",
        options: {
          display: false,
        },
      },
      { name: "Identifier" },
    ];
    testAdvSearchResults["columns"] = testAdvSearchColumns;

    var testAdvSearchData = [];
    if (data && data.length > 0) {
      for (var i in data) {
        testAdvSearchData.push([
          data[i].id,
          data[i].name,
          data[i].name,
          data[i].identifier,
        ]);
      }
      testAdvSearchResults["data"] = testAdvSearchData;
      this.setState({ testAdvSearchResults });
    } else {
      this.setState({ testAdvSearchResults });
    }
  }

  reorderTestPaperNumber(data) {
    for (var i = 0; i < data.length; i++) {
      data[i][0] = i + 1;
    }
    return data;
  }

  addSectionHandler = () => {
    var data = this.state.scheduletestPapersData;
    let now = offsetUTCTimezone(new Date());
    var tenMinutesFromNow = offsetUTCTimezone(new Date());
    tenMinutesFromNow.setTime(now.getTime() + 10 * 60 * 1000);

    var threeHoursFromStartTime = offsetUTCTimezone(new Date());
    threeHoursFromStartTime.setTime(
      tenMinutesFromNow.getTime() + 3.5 * 60 * 60 * 1000
    );
    threeHoursFromStartTime = threeHoursFromStartTime
      .toISOString()
      .substr(0, 16);

    tenMinutesFromNow = tenMinutesFromNow.toISOString().substr(0, 16);
    data.push([
      data.length + 1,
      "",
      tenMinutesFromNow,
      threeHoursFromStartTime,
      "03:00",
      "1",
      "",
      "",
      "",
    ]);

    var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
    newSectionrowIndexArray.push(data.length - 1);
    data = this.reorderTestPaperNumber(data);
    this.setState({
      scheduletestPapersData: data,
      newSectionrowIndexArray: newSectionrowIndexArray,
    });
  };

  handleSaveSection = (e) => {
    e.preventDefault();
    var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
    var rowIndex = newSectionrowIndexArray.indexOf(
      parseInt(e.currentTarget.id, 10)
    );
    if (rowIndex > -1) {
      newSectionrowIndexArray.splice(rowIndex, 1);
    }
    var data = this.state.scheduletestPapersData;
    data = this.reorderTestPaperNumber(data);
    this.setState({
      newSectionrowIndexArray: newSectionrowIndexArray,
      scheduletestPapersData: data,
    });
  };

  handleClearSection = (e) => {
    e.preventDefault();
    var data = this.state.scheduletestPapersData;
    data.splice(parseInt(e.currentTarget.id, 10), 1);

    var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
    var rowIndex = newSectionrowIndexArray.indexOf(
      parseInt(e.currentTarget.id, 10)
    );
    if (rowIndex > -1) {
      newSectionrowIndexArray.splice(rowIndex, 1);
    }
    data = this.reorderTestPaperNumber(data);
    this.setState({
      scheduletestPapersData: data,
      newSectionrowIndexArray: newSectionrowIndexArray,
    });
  };

  handleEditSection = (e) => {
    e.preventDefault();
    var newSectionrowIndexArray = this.state.newSectionrowIndexArray;
    newSectionrowIndexArray.push(parseInt(e.currentTarget.id, 10));

    this.setState({
      newSectionrowIndexArray: newSectionrowIndexArray,
    });
  };

  postScheduleTestPapersData(isRescheduleTestFlow) {
    var scheduletestPapersData = this.state.scheduletestPapersData;
    var scheduleTestInfo = this.state.scheduleTestInfo;

    var scheduleTestPapersPostData = [];
    for (var i in scheduletestPapersData) {
      var testPaperData = scheduletestPapersData[i];
      scheduleTestPapersPostData.push({
        paper_no: testPaperData[0],
        test_info_id: testPaperData[1].value,
        start_time: testPaperData[2],
        end_time: testPaperData[3],
        duration: testPaperData[4],
        no_of_attempts: testPaperData[5],
        password: testPaperData[6],
      });
    }
    var scheduleTestPostData = {
      scheduled_test: {
        name: scheduleTestInfo["name"],
        identifier: scheduleTestInfo["number"],
        description: scheduleTestInfo["description"],
        global_user_groups_info_id: scheduleTestInfo["userGroupId"],
        total_papers: scheduletestPapersData.length,
        test_type_id: scheduleTestInfo["testTypeId"],
        program_id: scheduleTestInfo["programId"],
        is_omr_test: scheduleTestInfo["testMode"] == "OFFLINE" ? true : false,
        scheduled_test_papers_info: JSON.stringify(scheduleTestPapersPostData),
      },
    };

    if (isRescheduleTestFlow) {
      scheduleTestPostData["scheduled_test"]["edit_form"] = true;
    }

    var rescheduleTestStringUrl = "";
    if (isRescheduleTestFlow) {
      rescheduleTestStringUrl =
        "?scheduled_test_id=" + this.state.rescheduleTestId;
    }
    if (scheduleTestPostData && scheduleTestPostData["scheduled_test"]) {
      $.ajax({
        type: "POST",
        url: getBaseURL() + "/react/scheduled_tests" + rescheduleTestStringUrl,
        data: scheduleTestPostData,
      })
        .done((response, status, jqXHR) => {
          if (response.is_valid) {
            this.props.history.push("/schedule-tests");
          }
        })
        .fail((jqXHR, textStatus, error) => {
          var errorMessages = this.state.errorMessages;
          errorMessages.push({ message: jqXHR["responseText"] });
          this.setState({ errorMessages: errorMessages });
          //TODO proper error handling
        });
    }
  }

  handleScheduleTest = (e) => {
    var scheduletestPapersData = this.state.scheduletestPapersData;
    var scheduleTestInfo = this.state.scheduleTestInfo;
    var errorMessages = [];
    if (!scheduleTestInfo["name"]) {
      errorMessages.push({ message: "Schedule Name is Required." });
    }
    if (!scheduleTestInfo["number"]) {
      errorMessages.push({ message: "Schedule Number is Required." });
    }
    if (!scheduleTestInfo["programId"]) {
      errorMessages.push({ message: "Program is Required." });
    }
    if (!scheduleTestInfo["testTypeId"]) {
      errorMessages.push({ message: "Test Type is Required." });
    }
    if (!scheduleTestInfo["testMode"]) {
      errorMessages.push({ message: "Test Mode is Required." });
    }
    if (!scheduleTestInfo["userGroupId"]) {
      errorMessages.push({
        message: "Please select a User Group. User Group is Required.",
      });
    }
    if (!(scheduletestPapersData.length > 0)) {
      errorMessages.push({
        message: "Atleast one Test Paper has to be scheduled.",
      });
    }
    if (this.state.newSectionrowIndexArray.length > 0) {
      errorMessages.push({
        message: "Please save all scheduled test papers and proceed",
      });
    }
    let now = new Date();
    for (var i in scheduletestPapersData) {
      var testPaper = scheduletestPapersData[i];
      var testPaperNumber = scheduletestPapersData[i][0];
      if (!testPaper[1]) {
        errorMessages.push({
          message: "Please select a test for paper number " + testPaperNumber,
        });
      }
      if (!testPaper[2]) {
        errorMessages.push({
          message:
            "Start Time not provided for paper number " + testPaperNumber,
        });
      }
      if (scheduleTestInfo["testMode"] != "OFFLINE") {
        if (new Date(testPaper[2]) < now) {
          errorMessages.push({
            message:
              "Start Time cannot have past time for paper number " +
              testPaperNumber,
          });
        }
        /*if(!testPaper[3]){
				errorMessages.push({message: "End Time not provided for paper number "+testPaperNumber});
			}*/
        if (testPaper[3] && new Date(testPaper[3]) < now) {
          errorMessages.push({
            message:
              "End Time cannot have past time for paper number " +
              testPaperNumber,
          });
        }
      }
      if (new Date(testPaper[2]) > new Date(testPaper[3])) {
        errorMessages.push({
          message:
            "End time cannot be before start time for paper number " +
            testPaperNumber,
        });
      }
      if (!testPaper[4]) {
        errorMessages.push({
          message: "Duration not provided for paper number " + testPaperNumber,
        });
      }
      if (!testPaper[5]) {
        errorMessages.push({
          message:
            "Possible Attempts not provided for paper number " +
            testPaperNumber,
        });
      }
      //TODO more datatypes validation and additional validation to be added
    }
    if (errorMessages.length > 0) {
      this.setState({ errorMessages: errorMessages });
      return;
    } else {
      this.setState({ errorMessages: [] });
    }

    var isRescheduleTestFlow = false;
    if (e.currentTarget.name == "reschedule_test") {
      isRescheduleTestFlow = true;
    }
    this.postScheduleTestPapersData(isRescheduleTestFlow);
  };

  handleCancelButton = (e) => {
    e.preventDefault();
    this.props.history.push("/schedule-tests");
  };

  render() {
    const { classes } = this.props;
    const {
      scheduleTestInfo,
      userGroupSelectList,
      userGroupAdvSearchResults,
      isEditFlow,
    } = this.state;
    const titleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingLeft: "20px",
    };
    const subtitleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px",
    };
    const customColumns = [
      {
        name: "Paper Number",
      },
      {
        name: "Test Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  minWidth: "130px",
                  zIndex: "100",
                  position: "relative",
                }}
              >
                {this.state.newSectionrowIndexArray.includes(
                  tableMeta.rowIndex
                ) ? (
                  <AutoSuggestSelect
                    name={
                      tableMeta.rowIndex.toString() +
                      "_" +
                      tableMeta.columnIndex.toString()
                    }
                    options={this.state.testSelectList}
                    value={value}
                    formControlWidth="100%"
                    onValueChange={this.handleTestNameLovChangeSections}
                    advSearchExecuteCallback={(e) =>
                      this.handleTestAdvSearch(tableMeta.rowIndex)
                    }
                    advSearchFormComponent={
                      <TestAdvSearchForm
                        testAdvSearchFormData={this.state.testAdvSearchFormData}
                        rowIndex={tableMeta.rowIndex.toString()}
                        handleOnChangeTestAdvSearchForm={
                          this.handleOnChangeTestAdvSearchForm
                        }
                      />
                    }
                    advSearchResults={this.state.testAdvSearchResults}
                    isComponentDisabled={
                      this.state.scheduleTestInfo["programId"] &&
                      this.state.scheduleTestInfo["testTypeId"] &&
                      this.state.scheduleTestInfo["testMode"]
                        ? false
                        : true
                    }
                  />
                ) : value ? (
                  value.label
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
      },
      {
        name: "Start Time",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {this.state.newSectionrowIndexArray.includes(
                  tableMeta.rowIndex
                ) ? (
                  <CustomInput
                    //labelText="Enter Section"
                    id="material2"
                    formControlProps={{
                      style: { width: "88%" },
                    }}
                    inputProps={{
                      id:
                        tableMeta.rowIndex.toString() +
                        "_" +
                        tableMeta.columnIndex.toString(),
                      style: { fontSize: "16px" },
                      type: "datetime-local",
                      defaultValue: tableMeta.rowData[2],
                      onChange: this.handleOnChangeSections,
                      placeholder: "Start Time",
                    }}
                  />
                ) : (
                  value && formatDate(offsetUTCTimezone(value))
                )}
              </div>
            );
          },
        },
      },
      {
        name: "End Time",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {this.state.newSectionrowIndexArray.includes(
                  tableMeta.rowIndex
                ) ? (
                  <CustomInput
                    //labelText="Enter Section"
                    id="material2"
                    formControlProps={{
                      style: { width: "88%" },
                    }}
                    inputProps={{
                      id:
                        tableMeta.rowIndex.toString() +
                        "_" +
                        tableMeta.columnIndex.toString(),
                      style: { fontSize: "16px" },
                      type: "datetime-local",
                      defaultValue: tableMeta.rowData[3],
                      onChange: this.handleOnChangeSections,
                      placeholder: "End Time",
                    }}
                  />
                ) : (
                  value && formatDate(offsetUTCTimezone(value))
                )}
              </div>
            );
          },
        },
      },
      {
        name: "Duration",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {this.state.newSectionrowIndexArray.includes(
                  tableMeta.rowIndex
                ) ? (
                  <CustomInput
                    //labelText="Enter Section"
                    id="material2"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      id:
                        tableMeta.rowIndex.toString() +
                        "_" +
                        tableMeta.columnIndex.toString(),
                      style: { fontSize: "16px" },
                      type: "time",
                      defaultValue: tableMeta.rowData[4],
                      onChange: this.handleOnChangeSections,
                      placeholder: "Duration",
                    }}
                  />
                ) : (
                  value
                )}
              </div>
            );
          },
        },
      },
      scheduleTestInfo?.testMode != "OFFLINE" && {
        name: "Attempts",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div style={{ maxWidth: "40px" }}>
                {this.state.newSectionrowIndexArray.includes(
                  tableMeta.rowIndex
                ) ? (
                  <CustomInput
                    //labelText="Enter Section"
                    id="material2"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      id:
                        tableMeta.rowIndex.toString() +
                        "_" +
                        tableMeta.columnIndex.toString(),
                      style: { fontSize: "16px" },
                      value: tableMeta.rowData[5],
                      onChange: this.handleOnChangeSections,
                      placeholder: "Attempts",
                    }}
                  />
                ) : (
                  value
                )}
              </div>
            );
          },
        },
      },
      getTestPasswordEnhacement() == "Y" &&
        scheduleTestInfo?.testMode != "OFFLINE" && {
          name: "Password",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {this.state.newSectionrowIndexArray.includes(
                    tableMeta.rowIndex
                  ) ? (
                    <CustomInput
                      //labelText="Enter Section"
                      id="material2"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        id:
                          tableMeta.rowIndex.toString() +
                          "_" +
                          tableMeta.columnIndex.toString(),
                        style: { fontSize: "16px" },
                        value: tableMeta.rowData[6],
                        onChange: this.handleOnChangeSections,
                        placeholder: "Password",
                      }}
                    />
                  ) : (
                    value
                  )}
                </div>
              );
            },
          },
        },
      {
        name: "",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <ErrorBoundary>
                  {this.state.newSectionrowIndexArray.includes(
                    tableMeta.rowIndex
                  ) ? (
                    <React.Fragment>
                      <GridContainer style={{ marginRight: "0px" }}>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          data-cy="scheduled test save btn"
                        >
                          <Tooltip title={"save"}>
                            <IconButton
                              id={tableMeta.rowIndex}
                              className={classes.iconButton}
                              onClick={this.handleSaveSection}
                            >
                              <SaveIcon className={classes.deleteIcon} />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Tooltip title={"delete"}>
                            <IconButton
                              id={tableMeta.rowIndex}
                              className={classes.iconButton}
                              onClick={this.handleClearSection}
                            >
                              <ClearIcon className={classes.deleteIcon} />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Tooltip title={"Edit"}>
                            <IconButton
                              id={tableMeta.rowIndex}
                              className={classes.iconButton}
                              onClick={this.handleEditSection}
                            >
                              <EditIcon className={classes.deleteIcon} />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <Tooltip title={"delete"}>
                            <IconButton
                              id={tableMeta.rowIndex}
                              className={classes.iconButton}
                              onClick={this.handleClearSection}
                            >
                              <ClearIcon className={classes.deleteIcon} />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                    </React.Fragment>
                  )}
                </ErrorBoundary>
              </React.Fragment>
            );
          },
        },
      },
    ];
    const options = {
      elevation: 0,
      filter: false,
      selectableRows: "none",
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      sort: false,
      responsive: "stacked",
      setRowProps: (row) => {
        return {
          style: { textAlign: "center" },
        };
      },
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return (
          <TestModelFooter
            title={"Add Test Paper"}
            changePage={changePage}
            count={count}
            handleAddSection={this.addSectionHandler}
          />
        );
      },
    };
    const userGroupAdvSearchForm = (
      <React.Fragment>
        <GridContainer
          style={{
            backgroundColor: "white",
            paddingTop: "0px",
            width: "100%",
            margin: "10px 0px 0px",
            marginTop: "0px",
          }}
          spacing={24}
        >
          <GridItem xs={12} sm={12} md={6} style={{ padding: "12px" }}>
            <InputLabel shrink htmlFor="material12">
              User Group Identifier
            </InputLabel>
            <CustomInput
              //labelText="Enter Test Model Name"
              id="material12"
              formControlProps={{
                fullWidth: true,
                style: { paddingTop: "5px" },
              }}
              inputProps={{
                style: { fontSize: "16px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <ShortText />
                  </InputAdornment>
                ),
                placeholder: "Enter Name",
                value: scheduleTestInfo["userGroupIdentifier"],
                onChange: this.handleOnChangeScheduleTestInfo,
                name: "scheduleTestInfo_userGroupIdentifier",
              }}
            />
            <InputLabel shrink htmlFor="material13">
              User Group Name
            </InputLabel>
            <CustomInput
              //labelText="Enter Test Model Identifier"
              id="material13"
              formControlProps={{
                fullWidth: true,
                style: { paddingTop: "5px" },
              }}
              inputProps={{
                style: { fontSize: "16px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <ShortText />
                  </InputAdornment>
                ),
                placeholder: "Enter Unique Key",
                value: scheduleTestInfo["userGroupName"],
                onChange: this.handleOnChangeScheduleTestInfo,
                name: "scheduleTestInfo_userGroupName",
              }}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <ErrorBoundary>
          <GridContainer
            style={{
              backgroundColor: "white",
              paddingTop: "0px",
              paddingBottom: "20px",
              width: "100%",
              margin: "10px 0px 0px",
              marginTop: "0px",
            }}
            spacing={24}
          >
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <h1 style={titleStyle}>
                {isEditFlow ? "Re-schedule Test" : "Schedule Test"}
              </h1>
              <hr />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                marginBottom: "-50px",
                zIndex: "1",
              }}
            >
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <Button
                    color="transparent"
                    onClick={this.handleCancelButton}
                    className={classes.navLink}
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    name="Cancel"
                    size="sm"
                  >
                    Cancel
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  {isEditFlow ? (
                    <Button
                      color="transparent"
                      onClick={this.handleScheduleTest}
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                      className={classes.navLink}
                      name="reschedule_test"
                      size="sm"
                    >
                      Re-Schedule Test
                    </Button>
                  ) : (
                    <Button
                      color="transparent"
                      onClick={this.handleScheduleTest}
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                      className={classes.navLink}
                      name="schedule_test"
                      size="sm"
                      data-cy="schedule test btn"
                    >
                      Schedule Test
                    </Button>
                  )}
                </ListItem>
              </List>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={1}
              style={{ padding: "12px" }}
            ></GridItem>
            <GridItem xs={12} sm={12} md={11} style={{ padding: "12px" }}>
              <h3 style={subtitleStyle}>Schedule Test Details</h3>
              {this.state.errorMessages &&
                this.state.errorMessages.length > 0 && (
                  <React.Fragment>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    ></GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={10}
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      {this.state.errorMessages &&
                      this.state.errorMessages.length > 0
                        ? this.state.errorMessages.map(
                            (errorMessage, index) => (
                              <div key={index}>
                                <ErrorLogo
                                  className={classes.icon}
                                  style={{
                                    verticalAlign: "middle",
                                    marginRight: "5px",
                                    color: "#f44336",
                                  }}
                                ></ErrorLogo>
                                <p
                                  style={{
                                    display: "inline-block",
                                    color: "#f44336",
                                    fontSize: "0.8rem",
                                    overflow: "inherit",
                                  }}
                                  className={classes.robotoFontRegular}
                                >
                                  {errorMessage["message"]}
                                </p>
                              </div>
                            )
                          )
                        : null}
                    </GridItem>
                  </React.Fragment>
                )}
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={2}
              style={{ padding: "12px" }}
            ></GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ padding: "12px" }}>
              <InputLabel
                shrink
                htmlFor="material"
                style={{ paddingTop: "4px" }}
              >
                Schedule Name
              </InputLabel>
              <CustomInput
                //labelText="Enter Test Model Name"
                style={{ paddingBottom: "8px" }}
                id="material"
                formControlProps={{
                  fullWidth: true,
                  style: { paddingTop: "5px" },
                }}
                inputProps={{
                  style: { fontSize: "16px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShortText />
                    </InputAdornment>
                  ),
                  placeholder: "Enter Name",
                  value: scheduleTestInfo["name"],
                  onChange: this.handleOnChangeScheduleTestInfo,
                  name: "scheduleTestInfo_name",
                }}
              />
              <InputLabel
                shrink
                htmlFor="material2"
                style={{ paddingTop: "4px" }}
              >
                Schedule Number
              </InputLabel>
              <CustomInput
                //labelText="Enter Test Model Identifier"
                style={{ paddingBottom: "8px" }}
                id="material2"
                formControlProps={{
                  fullWidth: true,
                  style: { paddingTop: "5px" },
                }}
                inputProps={{
                  style: { fontSize: "16px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShortText />
                    </InputAdornment>
                  ),
                  placeholder: "Enter Unique Key",
                  value: scheduleTestInfo["number"],
                  onChange: this.handleOnChangeScheduleTestInfo,
                  name: "scheduleTestInfo_number",
                }}
              />
              <InputLabel
                shrink
                htmlFor="material3"
                style={{ paddingTop: "4px" }}
              >
                Schedule Description
              </InputLabel>
              <CustomInput
                id="material3"
                style={{ paddingBottom: "8px" }}
                formControlProps={{
                  fullWidth: true,
                  style: { paddingTop: "5px" },
                }}
                inputProps={{
                  style: { fontSize: "16px" },
                  rows: "4",
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShortText />
                    </InputAdornment>
                  ),
                  placeholder: "Enter Description",
                  value: scheduleTestInfo["description"],
                  onChange: this.handleOnChangeScheduleTestInfo,
                  name: "scheduleTestInfo_description",
                }}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={1}
              style={{ padding: "12px" }}
            ></GridItem>
            <GridItem xs={12} sm={12} md={3} style={{ padding: "12px" }}>
              <div>
                {/*<InputLabel shrink htmlFor="material">
          			Total Papers
        		</InputLabel>
      			<h3 style={{fontSize: "16px"}}>{2}</h3>
      			<div style={{padding: "10px"}}/>*/}
                <InputLabel shrink htmlFor="material2">
                  Program
                </InputLabel>
                <div style={{ paddingTop: "5px" }}>
                  <Programs
                    name="scheduleTestInfo_program"
                    formControlWidth="75%"
                    value={scheduleTestInfo["program"]}
                    change={this.handleOnChangeLOVScheduleTestInfo.bind(this)}
                  />
                </div>
                <div style={{ padding: "10px" }} />
                <InputLabel shrink htmlFor="material2">
                  Test Type
                </InputLabel>
                <div style={{ paddingTop: "5px" }}>
                  <TestTypes
                    name="scheduleTestInfo_testType"
                    formControlWidth="75%"
                    value={scheduleTestInfo["testType"]}
                    change={this.handleOnChangeLOVScheduleTestInfo.bind(this)}
                  />
                </div>
                <div style={{ padding: "10px" }} />
                <InputLabel shrink htmlFor="material2">
                  Test Mode
                </InputLabel>
                <div style={{ paddingTop: "5px" }}>
                  <TestModes
                    name="scheduleTestInfo_testMode"
                    formControlWidth="75%"
                    value={scheduleTestInfo["testMode"]}
                    change={this.handleOnChangeLOVScheduleTestInfo.bind(this)}
                  />
                </div>
                <div style={{ padding: "10px" }} />
                <InputLabel shrink htmlFor="material2">
                  User Group
                </InputLabel>
                <div style={{ paddingTop: "5px" }}>
                  <AutoSuggestSelect
                    options={userGroupSelectList}
                    value={{
                      value: scheduleTestInfo["userGroupId"],
                      label: scheduleTestInfo["userGroupIdentifier"],
                    }}
                    formControlWidth="75%"
                    onValueChange={this.handleUserGroupChange}
                    name="scheduleTestInfo_userGroupId"
                    advSearchExecuteCallback={this.handleUserGroupAdvSearch}
                    advSearchFormComponent={userGroupAdvSearchForm}
                    advSearchResults={userGroupAdvSearchResults}
                  />
                </div>
                {/*<div style={{padding: "10px"}}/>
              	<InputLabel shrink htmlFor="material2">
          			Total Marks
        		</InputLabel>
              	<h3 style={{fontSize: "16px"}}></h3>*/}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}></GridItem>
            <GridItem
              style={{ zIndex: "0", padding: "24px 12px" }}
              xs={12}
              sm={12}
              md={12}
            >
              <MuiThemeProvider theme={getMuiThemeForTable}>
                <MUIDataTable
                  title={
                    <div>
                      <p style={subtitleStyle}>Schedule Test Papers</p>
                      {!(
                        this.state.scheduleTestInfo["programId"] &&
                        this.state.scheduleTestInfo["testTypeId"] &&
                        this.state.scheduleTestInfo["testMode"]
                      ) &&
                        this.state.scheduletestPapersData.length > 0 && (
                          <div>
                            <WarningLogo
                              className={classes.icon}
                              style={{
                                verticalAlign: "middle",
                                marginRight: "5px",
                                color: "#FF9800",
                              }}
                            ></WarningLogo>
                            <p
                              style={{
                                display: "inline-block",
                                color: "#FF9800",
                                fontSize: "0.8rem",
                                overflow: "inherit",
                              }}
                              className={classes.robotoFontRegular}
                            >
                              Select Program, Test Type and Test Mode
                            </p>
                          </div>
                        )}
                    </div>
                  }
                  data={this.state.scheduletestPapersData}
                  columns={customColumns}
                  options={options}
                />
              </MuiThemeProvider>
            </GridItem>
          </GridContainer>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default withStyles(TestModelFormStyle)(ScheduleTestForm);
