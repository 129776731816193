import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Divider from "@material-ui/core/Divider";
import Button from "CustomButtons/Button.jsx";
import $ from "jquery";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import Close from "@material-ui/icons/Close";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import OMRDataFormats from "components/lov/OMRDataFormats";
import InputLabel from "@material-ui/core/InputLabel";

const modalStyles = {
  ...modalStyle,
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ExpandableRowSchTestPaper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadOMRModal: false,
      omrDataUploadStatus: "",
      fileToImportName: "",
      fileToImport: "",
      OMRDataFormat: "OASIS",
    };
  }

  handleFileInput = (e) => {
    e.preventDefault();
    var fileToImportName = e.currentTarget.files[0].name;
    var fileToImport = e.currentTarget.files[0];
    this.setState({ fileToImportName, fileToImport });
  };

  handleFullScheduleReports = (e) => {
    e.preventDefault();
    var schTestId = e.currentTarget.name;
    $.ajax({
      type: "GET",
      url: getBaseURL() + "/total-test-analysis?scheduled_test_id=" + schTestId,
    })
      .done((data, status, jqXHR) => {
        var message = data["message"];
        if (message) {
          this.props.errorMessageCallback({ message: message });
        } else {
          this.props.storeStateCallback();
          this.props.history.push(
            "/total-test-analysis?scheduled_test_id=" + schTestId
          );
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.props.errorMessageCallback({
          message:
            "Error occured while fetching schedule analysis: " +
            jqXHR["responseJSON"].message,
        });
      });
  };

  handleViewReports = (e) => {
    e.preventDefault();
    var schTestPaperId = e.currentTarget.name;
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/test-paper-analysis?scheduled_test_paper_id=" +
        schTestPaperId,
    })
      .done((data, status, jqXHR) => {
        var message = data["message"];
        if (message) {
          this.props.errorMessageCallback({ message: message });
        } else {
          this.props.storeStateCallback();
          this.props.history.push(
            "/test-paper-analysis?scheduled_test_paper_id=" + schTestPaperId
          );
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.props.errorMessageCallback({
          message:
            "Error occured while fetching test analysis: " +
            jqXHR["responseJSON"].message,
        });
      });
  };

  handleReevaluateTest = (e) => {
    e.preventDefault();
    var schTestPaperId = e.currentTarget.name;
    var confirm_flag = confirm("Are you sure?");
    if (!confirm_flag) {
      e.preventDefault();
      return;
    }
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/re-evaluate-analysis?scheduled_test_paper_id=" +
        schTestPaperId,
    })
      .done((data, status, jqXHR) => {
        //TODO show some indicator that re-evaluation process was submitted in the background
        this.props.errorMessageCallback({
          message:
            "Test is being re-evaluated in the background. Please wait for upto 10 minutes for new results to reflect.",
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.props.errorMessageCallback({
          message:
            "Error occured while re-evaluating test due to " +
            jqXHR["responseText"],
        });
      });
  };

  handleRescheduleTest = (e) => {
    e.preventDefault();
    var schTestId = e.currentTarget.name;
    //this.props.storeStateCallback();
    this.props.history.push(
      "/scheduled_tests/new?edit=true&scheduled_test_id=" + schTestId
    );
  };

  handleCancelSchedule = (e) => {
    //TODO to be implemented
  };

  getLiveStats = (e) => {
    e.preventDefault();
    var schTestPaperId = e.currentTarget.name;
    this.props.history.push(
      "/test-live-users?scheduled_test_paper_id=" + schTestPaperId
    );
  };

  endOpenTest = (e) => {
    e.preventDefault();
    var schTestPaperId = e.currentTarget.name;
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/end-open-test?scheduled_test_paper_id=" +
        schTestPaperId,
    })
      .done((data, status, jqXHR) => {
        this.props.refreshSchedules();
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.props.errorMessageCallback({
          message:
            "Error occured while ending test due to " + jqXHR["responseText"],
        });
      });
  };

  uploadOMRData = (e) => {
    const schPaperId = e.currentTarget.name;
    var file = this.state.fileToImport;
    var OMRDataFormat = this.state.OMRDataFormat;
    if (!file) {
      alert("Please upload a valid file");
      return;
    }
    var url =
      getBaseURL() +
      "/upload-omr?scheduled_test_paper_id=" +
      schPaperId +
      "&format=" +
      OMRDataFormat;
    var formData = new FormData();
    formData.append("file", file);

    $.ajax({
      type: "POST",
      url: url,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
    })
      .done((data, status, jqXHR) => {
        this.setState({
          omrDataUploadStatus: "SUCCESS",
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.setState({
          omrDataUploadStatus: "FAILED",
        });
      });
  };

  render() {
    const {
      classes,
      schTestPapersData,
      schTestInfoData,
      errorMessageCallback,
    } = this.props;
    const { omrDataUploadStatus, fileToImportName, OMRDataFormat } = this.state;
    const searchButtonStyle = {
      textTransform: "none",
      backgroundColor: "#3f51b5",
      color: "white",
      borderRadius: "3px",
      textAlign: "right",
      "&:hover,&:focus": {
        color: "white",
        background: "#57849b",
      },
    };
    return (
      <React.Fragment>
        {schTestPapersData &&
          schTestPapersData.length > 0 &&
          schTestPapersData.map((schTestPaper, index) => (
            <React.Fragment key={schTestPaper[0]}>
              <TableRow
                style={{
                  backgroundColor: "rgba(224, 224, 224, 0.3)",
                  height: "36px",
                }}
              >
                <TableCell colSpan={2} style={{ border: "none" }}>
                  Paper No : {schTestPaper[1]}
                </TableCell>
                <TableCell colSpan={2} style={{ border: "none" }}>
                  Start Time : {schTestPaper[3]}
                </TableCell>
                <TableCell colSpan={2} style={{ border: "none" }}>
                  Duration : {schTestPaper[5]}
                </TableCell>
                <TableCell colSpan={2} style={{ border: "none" }}>
                  {schTestInfoData &&
                    schTestInfoData[4] &&
                    schTestInfoData[4] == "UPCOMING" && (
                      <React.Fragment>
                        <Button
                          name={schTestInfoData[0]}
                          size="sm"
                          onClick={this.handleRescheduleTest}
                          style={searchButtonStyle}
                        >
                          Re-schedule
                        </Button>
                        {/*<Button
				              name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleCancelSchedule}
				              style={searchButtonStyle}>
				              Cancel
				            </Button>*/}
                      </React.Fragment>
                    )}
                  {schTestInfoData &&
                    schTestInfoData[4] &&
                    (schTestInfoData[4] == "LIVE" ||
                      schTestInfoData[4] == "OPEN") && (
                      <React.Fragment>
                        <Button
                          name={schTestPaper[0]}
                          size="sm"
                          onClick={this.getLiveStats}
                          style={searchButtonStyle}
                        >
                          Live Stats
                        </Button>
                        {/*<Button
				              name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleCancelSchedule}
				              style={searchButtonStyle}>
				              Cancel
				            </Button>*/}
                      </React.Fragment>
                    )}
                  {schTestInfoData &&
                    schTestInfoData[4] &&
                    schTestInfoData[4] == "ENDED" &&
                    schTestInfoData[3] == "OFFLINE" && (
                      <React.Fragment>
                        <Button
                          name={schTestPaper[0]}
                          size="sm"
                          onClick={() =>
                            this.setState({ uploadOMRModal: true })
                          }
                          style={searchButtonStyle}
                        >
                          Upload OMR Data
                        </Button>
                        <Dialog
                          classes={{
                            root: classes.center,
                            paper: classes.modal,
                          }}
                          open={this.state.uploadOMRModal}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={() =>
                            this.setState({ uploadOMRModal: false })
                          }
                        >
                          <DialogTitle
                            disableTypography
                            className={classes.modalHeader}
                          >
                            <IconButton
                              className={classes.modalCloseButton}
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              onClick={() =>
                                this.setState({ uploadOMRModal: false })
                              }
                            >
                              <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>
                              Upload OMR Data
                            </h4>
                          </DialogTitle>
                          <DialogContent className={classes.modalBody}>
                            {omrDataUploadStatus && (
                              <p
                                style={
                                  omrDataUploadStatus == "SUCCESS"
                                    ? {
                                        width: "fit-content",
                                        fontSize: "16px",
                                        borderRadius: "5px",
                                        color: "white",
                                        backgroundColor: "#4caf50",
                                        padding: "5px",
                                      }
                                    : {
                                        width: "fit-content",
                                        fontSize: "16px",
                                        borderRadius: "5px",
                                        color: "white",
                                        backgroundColor: "#F44336",
                                        padding: "5px",
                                      }
                                }
                              >
                                {omrDataUploadStatus}
                              </p>
                            )}
                            <p>
                              Please note that this operation is not reversible
                              and might lead to data loss
                            </p>
                            <div style={{ padding: "10px" }} />
                            <InputLabel shrink htmlFor="material2">
                              OMR Data Format
                            </InputLabel>
                            <div style={{ paddingTop: "5px" }}>
                              <OMRDataFormats
                                formControlWidth="50%"
                                value={OMRDataFormat}
                                change={(e) =>
                                  this.setState({
                                    OMRDataFormat: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div style={{ padding: "10px" }} />
                            <div>
                              <input
                                accept=".csv"
                                className={classes.input}
                                style={{ display: "none" }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={this.handleFileInput}
                              />
                              <label htmlFor="raised-button-file">
                                <Button
                                  component="span"
                                  style={{
                                    textTransform: "none",
                                    backgroundColor: "#607D8B",
                                    padding: "8px 20px",
                                    margin: "0px",
                                  }}
                                >
                                  Browse
                                </Button>
                              </label>
                              {fileToImportName && (
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "rgb(34, 56, 69)",
                                  }}
                                >
                                  {fileToImportName}
                                </p>
                              )}
                            </div>
                          </DialogContent>
                          <DialogActions
                            className={
                              classes.modalFooter +
                              " " +
                              classes.modalFooterCenter
                            }
                          >
                            {!omrDataUploadStatus && (
                              <Button
                                name={schTestPaper[0]}
                                onClick={this.uploadOMRData}
                                color="success"
                              >
                                Upload OMR Data
                              </Button>
                            )}
                          </DialogActions>
                        </Dialog>
                      </React.Fragment>
                    )}
                </TableCell>
              </TableRow>
              <TableRow
                style={{
                  backgroundColor: "rgba(224, 224, 224, 0.3)",
                  height: "36px",
                }}
              >
                <TableCell colSpan={2} style={{ border: "none" }}>
                  {/*Custom expandable row option. Data: {JSON.stringify(rowData)}*/}
                  Test: {schTestPaper[2]}
                </TableCell>
                <TableCell colSpan={2} style={{ border: "none" }}>
                  End Time : {schTestPaper[4]}
                </TableCell>
                <TableCell colSpan={2} style={{ border: "none" }}>
                  Possible Attempts : {schTestPaper[6]}
                </TableCell>
                <TableCell colSpan={2} style={{ border: "none" }}>
                  {schTestInfoData &&
                    schTestInfoData[4] &&
                    schTestInfoData[4] == "ENDED" && (
                      <React.Fragment>
                        <Button
                          name={schTestPaper[0]}
                          size="sm"
                          onClick={this.handleViewReports}
                          style={{ ...searchButtonStyle, marginRight: "8px" }}
                          data-cy="manage schedules view reports btn"
                        >
                          View Reports
                        </Button>
                        <Button
                          name={schTestPaper[0]}
                          size="sm"
                          onClick={this.handleReevaluateTest}
                          style={searchButtonStyle}
                        >
                          Re-evaluate Test
                        </Button>
                      </React.Fragment>
                    )}
                  {schTestInfoData &&
                    schTestInfoData[4] &&
                    schTestInfoData[4] == "OPEN" && (
                      <React.Fragment>
                        <Button
                          name={schTestPaper[0]}
                          size="sm"
                          onClick={this.endOpenTest}
                          style={searchButtonStyle}
                          data-cy="manage schedules end test btn"
                        >
                          End Test
                        </Button>
                        {/*<Button
				              name={schTestInfoData[0]}    
				              size="sm"
				              onClick={this.handleCancelSchedule}
				              style={searchButtonStyle}>
				              Cancel
				            </Button>*/}
                      </React.Fragment>
                    )}
                </TableCell>
              </TableRow>
              {schTestPapersData.length > 1 &&
                index == schTestPapersData.length - 1 && (
                  <React.Fragment>
                    <TableRow
                      style={{
                        backgroundColor: "white",
                        height: "36px",
                        borderBottom: "1px solid rgba(228,228,228)",
                      }}
                    >
                      <TableCell
                        colSpan={3}
                        style={{ border: "none" }}
                      ></TableCell>
                      <TableCell colSpan={2} style={{ border: "none" }}>
                        {schTestInfoData &&
                          schTestInfoData[4] &&
                          schTestInfoData[4] == "ENDED" && (
                            <Button
                              name={schTestInfoData[0]}
                              size="sm"
                              onClick={this.handleFullScheduleReports}
                              style={searchButtonStyle}
                            >
                              Full Schedule Summary
                            </Button>
                          )}
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        style={{ border: "none" }}
                      ></TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
              <Divider style={{ backgroundColor: "white", height: "3px" }} />
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  }
}

export default withStyles(modalStyles)(ExpandableRowSchTestPaper);
