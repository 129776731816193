import React from "react";
import { getBaseURL } from "components/helpers/HostURLHelper.jsx";
import $ from "jquery";
import qs from "query-string";

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import TestQuestionsContainer from "components/test/view/TestQuestionsContainer";
import TestNavigator from "components/test/view/TestNavigator";
import TestHeader from "components/test/view/TestHeader.jsx";

//import html2canvas from 'html2canvas';
//import jsPDF from 'jspdf';
//import html2pdf from 'html2pdf.js';

import AutoSuggestSelect from "components/misc/AutoSuggestSelect";
import TestQuestionsContainerListView from "components/test/view/TestQuestionsContainerListView";

import CustomInput from "CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import ShortText from "@material-ui/icons/ShortText";
import {
  validateNumberFormatForUpto2DecimalNumbers,
  validateNumberFormatForIntegerNumbers,
} from "components/helpers/InputFormatHelper.jsx";

import DatasetMain from "components/dataset/DatasetMain";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import MetaReadDataListView from "metaread/MetaReadDataListView";
import Button from "@material-ui/core/Button";

function TestAdvSearchForm(props) {
  return (
    <React.Fragment>
      <GridContainer
        style={{
          backgroundColor: "white",
          paddingTop: "0px",
          width: "100%",
          margin: "10px 0px 0px",
          marginTop: "0px",
        }}
        spacing={24}
      >
        <GridItem xs={12} sm={12} md={6} style={{ padding: "12px" }}>
          <InputLabel shrink htmlFor="material12">
            Test Name
          </InputLabel>
          <CustomInput
            //labelText="Enter Test Model Name"
            id="material12"
            formControlProps={{
              fullWidth: true,
              style: { paddingTop: "5px" },
            }}
            inputProps={{
              style: { fontSize: "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <ShortText />
                </InputAdornment>
              ),
              placeholder: "Enter Name",
              value: props.fetchSourceTestAdvSearchFormData["testName"],
              onChange: props.handleOnChangeTestAdvSearchForm,
              name: "testName",
            }}
          />
          <InputLabel shrink htmlFor="material13">
            Test Number
          </InputLabel>
          <CustomInput
            //labelText="Enter Test Model Identifier"
            id="material13"
            formControlProps={{
              fullWidth: true,
              style: { paddingTop: "5px" },
            }}
            inputProps={{
              style: { fontSize: "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <ShortText />
                </InputAdornment>
              ),
              placeholder: "Enter Unique Key",
              value: props.fetchSourceTestAdvSearchFormData["testNumber"],
              onChange: props.handleOnChangeTestAdvSearchForm,
              name: "testNumber",
            }}
          />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

class TestRegion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testData: {},
      initialTestData: {},
      currentQuestionData: {},
      navTestData: [],
      firstQ: true,
      lastQ: false,
      navfocusSubjectIndex: 0,
      isEditMode: false,
      currentQuestionNumber: 1,
      questionSavedIndicator: false,
      testId: "",
      isTestScheduled: false,
      testInfoData: {},
      testViewMode: "grid",
      isFetchQuestionsView: false,
      fetchSourceTestData: {},
      filteredFetchSourceTestData: {},
      fetchSourceTestSelectList: [],
      fetchSourceTestAdvSearchResults: {},
      fetchSourceTestAdvSearchFormData: {},
      fetchQuestionsSource: "",
      mr_type: "",
      projectId: "",
      projectSearchResults: [],
      projectFragments: [],
      errorsReported: [],
      isApprover: false,
      fetchedQuestionsSourceIds: [],
    };
  }

  componentDidMount() {
    const test_id = qs.parse(this.props.location.search).test_id;
    /*const question_no = qs.parse(this.props.location.search).question_no;*/ //SCOPING OUT QUESTION NUMBERING FOR NOW
    //TODO what happens if test_id and question_no are not passed
    if (test_id) {
      this.setState({ testId: test_id });
    }
    const mode = qs.parse(this.props.location.search).mode;

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/test/preview?test_id=" + test_id, //Hard Coded TODO
      dataType: "JSON",
    }).done((data) => {
      if (
        data &&
        data["template_details"] &&
        data["template_details"].length > 0
      ) {
        this.initializeTestDataForEditMode(data);
        this.getTestNavigationDataFromTestData(data);

        if (mode != "view") {
          const testInfoData = this.state.testInfoData;
          if (testInfoData.status == "COMPLETED") {
            if (
              confirm("You are about to edit a completed test. Are you sure?")
            ) {
              this.handleEditCompletedTest();
              this.setState({ isEditMode: true });
            } else {
              this.setState({ isEditMode: false });
            }
          } else {
            this.setState({ isEditMode: true });
          }
        }

        //for indicators while fetching question from dataset
        this.initializeQuestionSourceIds(data?.questions);
      } else {
        //TODO handle no data case
      }
    });
  }

  initializeTestDataForEditMode(data) {
    var qOrder = 0;
    for (
      var templateIndx = 0;
      templateIndx < data["template_details"].length;
      templateIndx++
    ) {
      var currentSection = data["template_details"][templateIndx];
      for (
        var qNumber = currentSection.first_question_no;
        qNumber <= currentSection.last_question_no;
        qNumber++
      ) {
        if (
          !data["questions"][qOrder] ||
          data["questions"][qOrder]["question_no"] != qNumber
        ) {
          var qData = {
            global_subject_id: currentSection.global_subject_id,
            negative_marks: currentSection.negative_marks,
            partial_marks: currentSection.partial_marks,
            pattern_name: currentSection.pattern_name,
            pattern_short_name: currentSection.pattern_short_name,
            positive_marks: currentSection.positive_marks,
            question_no: qNumber,
            subject_name: currentSection.subject_name,
            subject_short_name: currentSection.subject_short_name,
            test_info_id: this.state.testId,
            key: "[[]]",
            solution: '[["",""]]',
            question_content: {
              question: [["", ""]],
            },
          };
          if (currentSection.pattern_short_name == "PASSAGE") {
            qData["question_content"]["passage"] = [["", ""]];
            qData["question_content"]["options"] = [
              { label: "A", content: ["", ""] },
              { label: "B", content: ["", ""] },
              { label: "C", content: ["", ""] },
              { label: "D", content: ["", ""] },
            ];
          } else if (currentSection.pattern_short_name == "MM") {
            qData["question_content"]["list1"] = [[["", ""]]];
            qData["question_content"]["list2"] = [[["", ""]]];
          } else if (
            currentSection.pattern_short_name != "INTEGER" &&
            currentSection.pattern_short_name != "NUMERICAL"
          ) {
            qData["question_content"]["options"] = [
              { label: "A", content: ["", ""] },
              { label: "B", content: ["", ""] },
              { label: "C", content: ["", ""] },
              { label: "D", content: ["", ""] },
            ];
          }
          data["questions"].splice(qNumber - 1, 0, qData);
        } else if (
          currentSection.pattern_short_name != "MM" ||
          currentSection.pattern_short_name != "INTEGER" ||
          currentSection.pattern_short_name != "NUMERICAL"
        ) {
          if (!data["questions"][qOrder]?.question_content?.options) {
            if (!data["questions"][qOrder]?.question_content) {
              data["questions"][qOrder]["question_content"] = {};
            }
            data["questions"][qOrder]["question_content"]["options"] = [
              { label: "A", content: ["##A", ""] },
              { label: "B", content: ["##B", ""] },
              { label: "C", content: ["##C", ""] },
              { label: "D", content: ["##D", ""] },
            ];
          } else if (
            data["questions"][qOrder]["question_content"]["options"].length != 4
          ) {
            var optA = false;
            var optB = false;
            var optC = false;
            var optD = false;
            for (var i = 0; i < 4; i++) {
              if (data["questions"][qOrder]["question_content"]["options"][i]) {
                if (
                  data["questions"][qOrder]["question_content"]["options"][i][
                    "label"
                  ] == "A"
                ) {
                  optA = true;
                }
                if (
                  data["questions"][qOrder]["question_content"]["options"][i][
                    "label"
                  ] == "B"
                ) {
                  optB = true;
                }
                if (
                  data["questions"][qOrder]["question_content"]["options"][i][
                    "label"
                  ] == "C"
                ) {
                  optC = true;
                }
                if (
                  data["questions"][qOrder]["question_content"]["options"][i][
                    "label"
                  ] == "D"
                ) {
                  optD = true;
                }
              }
            }
            var optJson = {};
            if (!optA) {
              optJson = { label: "A", content: ["##A", ""] };
              data["questions"][qOrder]["question_content"]["options"].splice(
                0,
                0,
                optJson
              );
            }
            if (!optB) {
              optJson = { label: "B", content: ["##B", ""] };
              data["questions"][qOrder]["question_content"]["options"].splice(
                1,
                0,
                optJson
              );
            }
            if (!optC) {
              optJson = { label: "C", content: ["##C", ""] };
              data["questions"][qOrder]["question_content"]["options"].splice(
                2,
                0,
                optJson
              );
            }
            if (!optD) {
              optJson = { label: "D", content: ["##D", ""] };
              data["questions"][qOrder]["question_content"]["options"].splice(
                3,
                0,
                optJson
              );
            }
          }
        } else if (
          !data["questions"][qOrder]["question_content"] ||
          !data["questions"][qOrder]["question_content"]["question"]
        ) {
          data["questions"][qOrder]["question_content"]["question"] = [
            ["####Q", ""],
          ];
        }
        qOrder = qOrder + 1;
      }
    }
    this.setState({
      testData: data,
      initialTestData: JSON.parse(JSON.stringify(data)), //copy data without reference
      currentQuestionData: data["questions"][0],
      currentQuestionNumber: 1,
      isTestScheduled: data["is_test_scheduled"],
      isApprover: data["approver_priv"],
      testInfoData: data["test_details"],
    });
  }

  convLettersToNumbers(str) {
    "use strict";
    var out = 0,
      len = str.length,
      pos = len;
    while (--pos > -1) {
      out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - 1 - pos);
    }
    return out.toString();
  }

  convNumbersToLetters(int) {
    return String.fromCharCode(64 + parseInt(int));
  }

  convMAQAnswerDataLettersToNumber(answer) {
    if (!answer) {
      return;
    }
    var ansNumberArray = new Array();
    const ansLettersArray = answer.split(",");
    for (var i in ansLettersArray) {
      ansNumberArray.push(this.convLettersToNumbers(ansLettersArray[i]));
    }
    return ansNumberArray;
  }

  convMAQDataNumbersToLetters(ansNumberArray) {
    if (!ansNumberArray || !ansNumberArray[0]) {
      return;
    }
    var ansLetterArray = "";
    for (var i in ansNumberArray[0]) {
      if (ansLetterArray != "")
        ansLetterArray =
          ansLetterArray +
          "," +
          String.fromCharCode(64 + parseInt(ansNumberArray[0][i]));
      else
        ansLetterArray = String.fromCharCode(
          64 + parseInt(ansNumberArray[0][i])
        );
    }
    return ansLetterArray;
  }

  getTestNavigationDataFromTestData(data) {
    if (data["template_details"] != null) {
      var oldSubject = data["template_details"][0].global_subject_id;
      var oldSubPattern =
        data["template_details"][0].global_question_pattern_id;
      var subPatternArr = [
        {
          pattern: data["template_details"][0].pattern_name,
          first_question: data["template_details"][0].first_question_no,
          last_question: data["template_details"][0].last_question_no,
          positive_marks: data["template_details"][0].positive_marks,
          negative_marks: data["template_details"][0].negative_marks,
          partial_marks: data["template_details"][0].partial_marks,
        },
      ];
      var navTestData = [
        {
          subject: data["template_details"][0].subject_name,
          first_question: data["template_details"][0].first_question_no,
          patterns: subPatternArr,
          last_question: data["template_details"][0].last_question_no,
        },
      ];
      var subjects_count = 0;
      var subLastQuestion = data["template_details"][0].last_question_no;
      for (var i = 0; i < data["template_details"].length; i++) {
        var currSubject = data["template_details"][i].global_subject_id;
        var currSubPattern =
          data["template_details"][i].global_question_pattern_id;
        if (currSubject != oldSubject) {
          navTestData[subjects_count]["last_question"] = subLastQuestion;
          subPatternArr = [
            {
              pattern: data["template_details"][i].pattern_name,
              first_question: data["template_details"][i].first_question_no,
              last_question: data["template_details"][i].last_question_no,
              positive_marks: data["template_details"][i].positive_marks,
              negative_marks: data["template_details"][i].negative_marks,
              partial_marks: data["template_details"][i].partial_marks,
            },
          ];
          navTestData.push({
            subject: data["template_details"][i].subject_name,
            first_question: data["template_details"][i].first_question_no,
            patterns: subPatternArr,
            last_question: data["template_details"][i].last_question_no,
          });
          subLastQuestion = data["template_details"][i].last_question_no;
          oldSubPattern = currSubPattern;
          oldSubject = currSubject;
          subjects_count++;
        } else if (currSubPattern != oldSubPattern) {
          subPatternArr.push({
            pattern: data["template_details"][i].pattern_name,
            first_question: data["template_details"][i].first_question_no,
            last_question: data["template_details"][i].last_question_no,
            positive_marks: data["template_details"][i].positive_marks,
            negative_marks: data["template_details"][i].negative_marks,
            partial_marks: data["template_details"][i].partial_marks,
          });
          subLastQuestion = data["template_details"][i].last_question_no;
          oldSubPattern = currSubPattern;
        } else if (
          i != 0 &&
          currSubPattern == oldSubPattern &&
          currSubject == oldSubject
        ) {
          subPatternArr.push({
            pattern: data["template_details"][i].pattern_name,
            first_question: data["template_details"][i].first_question_no,
            last_question: data["template_details"][i].last_question_no,
          });
          subLastQuestion = data["template_details"][i].last_question_no;
        }
      }
      navTestData[subjects_count]["patterns"] = subPatternArr;
      navTestData[subjects_count]["last_question"] = subLastQuestion;
      this.setState({ navTestData: navTestData });
    }
  }

  // handleNavfocusSubject = (event, value) => {
  //    this.setState({ navfocusSubjectIndex: value });
  // };

  handleNavfocusSubject = (e) => {
    e.preventDefault();
    this.navToQuestion(e);
    this.setState({ navfocusSubjectIndex: e.currentTarget.name });
  };

  navNextQuestion = (e) => {
    this.handleSaveQuestionData(e);

    var currQuestionNumber = this.state.currentQuestionData.question_no; //TODO Error handling if there is not currentQuestionData

    var data = this.state.testData; //TODO Error handling if testData is not present
    if (currQuestionNumber < data["questions"].length)
      this.setState({
        currentQuestionData: data["questions"][currQuestionNumber],
        currentQuestionNumber: currQuestionNumber + 1,
        questionSavedIndicator: false,
      });

    if (currQuestionNumber == data["questions"].length - 1)
      this.setState({ lastQ: true, firstQ: false });
    else this.setState({ lastQ: false, firstQ: false });

    const navTestData = this.state.navTestData;
    const nextQuestionNumber = currQuestionNumber + 1;
    var navfocusSubjectIndex = 0;
    for (var i = 0; i < navTestData.length; i++) {
      if (nextQuestionNumber > navTestData[i]["last_question"]) {
        navfocusSubjectIndex++;
      }
    }
    this.setState({ navfocusSubjectIndex: navfocusSubjectIndex });

    if (this.state.isFetchQuestionsView) {
      this.filterFetchSourceTestData(currQuestionNumber + 1);
    }
  };

  navPrevQuestion = (e) => {
    this.handleSaveQuestionData(e);

    var currQuestionNumber = this.state.currentQuestionData.question_no; //TODO Error handling if there is not currentQuestionData

    var data = this.state.testData; //TODO Error handling if testData is not present
    if (currQuestionNumber - 2 >= 0)
      this.setState({
        currentQuestionData: data["questions"][currQuestionNumber - 2],
        currentQuestionNumber: currQuestionNumber - 1,
        questionSavedIndicator: false,
      });

    if (currQuestionNumber - 2 == 0)
      this.setState({ firstQ: true, lastQ: false });
    else this.setState({ firstQ: false, lastQ: false });

    const navTestData = this.state.navTestData;
    const prevQuestionNumber = currQuestionNumber - 1;
    var navfocusSubjectIndex = 0;
    for (var i = 0; i < navTestData.length; i++) {
      if (prevQuestionNumber > navTestData[i]["last_question"]) {
        navfocusSubjectIndex++;
      }
    }
    this.setState({ navfocusSubjectIndex: navfocusSubjectIndex });

    if (this.state.isFetchQuestionsView) {
      this.filterFetchSourceTestData(currQuestionNumber - 1);
    }
  };

  navToQuestion = (e) => {
    this.handleSaveQuestionData(e);
    var qNumber = e.currentTarget.id; //TODO Error Handling
    this.navToQuestionNumber(qNumber);
  };

  navToQuestionNumber = (qNumber) => {
    var data = this.state.testData; //TODO Error handling if testData is not present
    if (qNumber >= 1 && qNumber <= data["questions"].length) {
      this.setState({
        currentQuestionData: data["questions"][qNumber - 1],
        currentQuestionNumber: qNumber,
        questionSavedIndicator: false,
      });
    }

    if (qNumber == data["questions"].length)
      this.setState({ lastQ: true, firstQ: false });
    else if (qNumber == 1) this.setState({ lastQ: false, firstQ: true });
    else this.setState({ lastQ: false, firstQ: false });

    if (this.state.isFetchQuestionsView) {
      this.filterFetchSourceTestData(qNumber);
    }
  };

  handleEditTestData = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    if (!component) {
      component = e.target.name;
    }
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "options") {
      for (var optionIndex in currentQuestionData["question_content"][
        "options"
      ]) {
        if (
          currentQuestionData["question_content"]["options"][optionIndex]
            .label == compArray[1]
        ) {
          currentQuestionData["question_content"]["options"][
            optionIndex
          ].content[compArray[2]] = e.currentTarget.value;
        }
      }
    } else if (compArray[0] == "solution") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      jsonForm[compArray[1]][compArray[2]] = e.currentTarget.value;
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else if (compArray[0] == "key") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      if (currentQuestionData["pattern_short_name"] == "NUMERICAL") {
        if (validateNumberFormatForUpto2DecimalNumbers(e.currentTarget.value)) {
          var str = e.currentTarget.value;
          // if(str && str[str.length -1]!="."){
          //   str = parseFloat(str).toString();
          // }
          jsonForm[compArray[1]][0] = str;
        }
      } else if (currentQuestionData["pattern_short_name"] == "INTEGER") {
        if (validateNumberFormatForIntegerNumbers(e.currentTarget.value)) {
          jsonForm[compArray[1]][0] = e.currentTarget.value;
        }
      } else if (currentQuestionData["pattern_short_name"] == "MM") {
        jsonForm[compArray[1]] = e.target.value;
      } else if (currentQuestionData["pattern_short_name"] == "MAQ") {
        jsonForm[compArray[1]] = e.target.value;
      } else {
        jsonForm[compArray[1]][0] = e.target.value;
      }
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ][compArray[3]] = e.currentTarget.value;
    } else {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ] = e.currentTarget.value;
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleAddTextComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "solution") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      jsonForm.splice(compArray[1] + 1, 0, ["", ""]);
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else {
      currentQuestionData["question_content"][compArray[0]].splice(
        compArray[1] + 1,
        0,
        ["", ""]
      );
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleAddListComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"]["list1"].push([["", ""]]);
      currentQuestionData["question_content"]["list2"].push([["", ""]]);
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleRemoveListComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"]["list1"].pop();
      currentQuestionData["question_content"]["list2"].pop();
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleRemoveTextComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "solution") {
      if (compArray[1] > 0) {
        var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
        jsonForm.splice(compArray[1], 1);
        currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
      }
    } else {
      if (compArray[1] > 0) {
        currentQuestionData["question_content"][compArray[0]].splice(
          compArray[1],
          1
        );
      }
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handleRemoveImageComponent = (e) => {
    var currentQuestionData = this.state.currentQuestionData;
    var component = e.currentTarget.name;
    var compArray = component.split("_");
    //TODO error handling
    if (compArray[0] == "options") {
      for (var optionIndex in currentQuestionData["question_content"][
        "options"
      ]) {
        if (
          currentQuestionData["question_content"]["options"][optionIndex]
            .label == compArray[1]
        ) {
          currentQuestionData["question_content"]["options"][
            optionIndex
          ].content[compArray[2]] = "";
        }
      }
    } else if (compArray[0] == "solution") {
      var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
      jsonForm[compArray[1]][compArray[2]] = "";
      currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
    } else if (compArray[0] == "list1" || compArray[0] == "list2") {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ][1] = "";
    } else {
      currentQuestionData["question_content"][compArray[0]][compArray[1]][
        compArray[2]
      ] = "";
    }
    this.setState({ currentQuestionData, questionSavedIndicator: false });
  };

  handlePasteEvent = (e) => {
    var clipboardData = e.clipboardData;
    if (clipboardData) {
      if (clipboardData.items.length == 0) return;

      $.each(
        clipboardData.items,
        function (i, item) {
          if (item.type.indexOf("image") !== -1) {
            this.insertBinaryImage(e, item.getAsFile());
          }
          /*if(item.type.indexOf("text") !== -1){
                    //removeImage(item);
                }*/
        }.bind(this)
      );
      return false;
    }
  };

  handleImageFileUpload = (e) => {
    if (
      e.target.files.length > 0 &&
      e.target.files[e.target.files.length - 1].type.indexOf("image") !== -1
    ) {
      this.insertBinaryImage(e, e.target.files[0]);
    }
  };

  insertBinaryImage(e, file) {
    var reader = new FileReader();
    var component = e.target.name;
    reader.addEventListener(
      "loadend",
      function () {
        var img = new Image();
        img.src = reader.result;
        //img.height = "200";
        //img.width="200";

        var compArray = component.split("_");
        var currentQuestionData = this.state.currentQuestionData;
        const test_id = this.state.testId;
        const timestamp = new Date().getUTCMilliseconds();
        var imgExtension = "";
        if (img.src.indexOf("svg+xml") !== -1) {
          imgExtension = ".svg";
          // }else if(img.src.indexOf('wmf') !== -1){
          //   imgExtension = ".wmf"
        } else if (img.src.indexOf("png") !== -1) {
          imgExtension = ".png";
        } else if (img.src.indexOf("jpg") !== -1) {
          imgExtension = ".jpg";
        } else if (img.src.indexOf("jpeg") !== -1) {
          imgExtension = ".jpeg";
        }

        if (!imgExtension) {
          alert(
            "Image format not supported. Please upload images in supported format."
          );
          return;
        }

        var image_path =
          compArray[0] +
          "_" +
          test_id +
          "_" +
          currentQuestionData["question_no"] +
          "_" +
          compArray[1] +
          "_" +
          timestamp +
          imgExtension;

        if (compArray[0] == "list1" || compArray[0] == "list2") {
          image_path =
            compArray[0] +
            "_" +
            test_id +
            "_" +
            currentQuestionData["question_no"] +
            "_" +
            compArray[1] +
            "_" +
            compArray[2] +
            "_" +
            timestamp +
            imgExtension;
        }

        var base64_encoded = {};
        if (currentQuestionData["base64_encoded"]) {
          base64_encoded = currentQuestionData["base64_encoded"];
        }
        if (img.src) {
          base64_encoded[image_path] = img.src;
          currentQuestionData["base64_encoded"] = base64_encoded;
          if (compArray[0] == "options") {
            for (var optionIndex in currentQuestionData["question_content"][
              "options"
            ]) {
              if (
                currentQuestionData["question_content"]["options"][optionIndex]
                  .label == compArray[1]
              ) {
                if (
                  currentQuestionData["question_content"]["options"][
                    optionIndex
                  ].content.length >
                  parseInt(compArray[2]) + 1
                ) {
                  currentQuestionData["question_content"]["options"][
                    optionIndex
                  ].content[parseInt(compArray[2]) + 1] =
                    "/get-image?name=" + image_path;
                } else {
                  currentQuestionData["question_content"]["options"][
                    optionIndex
                  ].content.append("/get-image?name=" + image_path);
                }
              }
            }
          } else if (compArray[0] == "solution") {
            var jsonForm = JSON.parse(currentQuestionData[compArray[0]]);
            jsonForm[compArray[1]][1] = "/get-image?name=" + image_path;
            currentQuestionData[compArray[0]] = JSON.stringify(jsonForm);
          } else if (compArray[0] == "list1" || compArray[0] == "list2") {
            currentQuestionData["question_content"][compArray[0]][compArray[1]][
              compArray[2]
            ][1] = "/get-image?name=" + image_path;
          } else {
            currentQuestionData["question_content"][compArray[0]][
              compArray[1]
            ][1] = "/get-image?name=" + image_path;
          }
          this.setState({ currentQuestionData, questionSavedIndicator: false });
          this.handleSaveQuestionData(e);
        }
      }.bind(this)
    );
    reader.readAsDataURL(file);
  }

  handleExplicitSave(currentQuestionData) {
    this.handleExplicitSave(currentQuestionData, "");
  }

  handleExplicitSave(currentQuestionData, saveActionSourceComponent) {
    var finalPostQuestionData = {};
    var postQuestionData = {};
    postQuestionData["question_no"] = currentQuestionData["question_no"];
    postQuestionData["question_content"] = JSON.stringify(
      currentQuestionData["question_content"]
    );
    postQuestionData["test_info_id"] = currentQuestionData["test_info_id"];
    postQuestionData["question_key"] = currentQuestionData["key"];
    postQuestionData["question_solution"] = currentQuestionData["solution"];
    postQuestionData["base64_encoded"] = JSON.stringify(
      currentQuestionData["base64_encoded"]
    );
    if (
      currentQuestionData["question_source"] &&
      currentQuestionData["question_source_id"]
    ) {
      postQuestionData["question_source"] =
        currentQuestionData["question_source"];
      postQuestionData["question_source_id"] =
        currentQuestionData["question_source_id"];
    }

    if (!currentQuestionData["base64_encoded"]) {
      postQuestionData["base64_encoded"] = JSON.stringify({});
    }
    postQuestionData["source"] = "react";
    finalPostQuestionData["new_question"] = postQuestionData;

    $.ajax({
      type: "POST",
      url: getBaseURL() + "/test_questions",
      data: finalPostQuestionData,
    })
      .done((response, status, jqXHR) => {
        if (saveActionSourceComponent == "save") {
          this.setState({ questionSavedIndicator: true });
        }
      })
      .fail((jqXHR, textStatus, error) => {
        alert(jqXHR["responseText"]);
        return;
      });
  }

  handleSaveQuestionData = (e) => {
    e.preventDefault();
    var action = "";
    if (e && e.currentTarget) {
      var comp_name = e.currentTarget.name;
      if (comp_name) {
        var parts = comp_name.split("_");
        if (parts && parts[0]) {
          action = parts[0];
        }
      }
    }

    if (action != "fetch" && !this.state.isEditMode) {
      return;
    }

    var initialTestData = this.state.initialTestData;
    var currentQuestionData = this.state.currentQuestionData;
    const currentQuestionNumber = currentQuestionData["question_no"];

    if (
      JSON.stringify(currentQuestionData) ===
      JSON.stringify(
        initialTestData["questions"][parseInt(currentQuestionNumber) - 1]
      )
    ) {
      return;
    }

    var saveActionSourceComponent = "";
    if (e && e.currentTarget && e.currentTarget.name) {
      saveActionSourceComponent = e.currentTarget.name;
    }

    this.handleExplicitSave(currentQuestionData, saveActionSourceComponent);
  };

  handleClearQuestionData = (e) => {
    e.preventDefault();
    var currentQuestionData = this.state.currentQuestionData;

    currentQuestionData["key"] = "[[]]";
    currentQuestionData["solution"] = '[["",""]]';
    currentQuestionData["question_content"] = {
      options: [
        { label: "A", content: ["", ""] },
        { label: "B", content: ["", ""] },
        { label: "C", content: ["", ""] },
        { label: "D", content: ["", ""] },
      ],
      question: [["", ""]],
      passage: [["", ""]],
    };
    this.setState({ currentQuestionData });
  };

  handleClearKey = (e) => {
    e.preventDefault();
    var currentQuestionData = this.state.currentQuestionData;
    currentQuestionData["key"] = "[[]]";
    this.setState({ currentQuestionData });
  };

  //DEPRECATED Removed the feature temporarily
  handleDiscardQuestionData = (e) => {
    e.preventDefault();
    const currentQuestionNumber = this.state.currentQuestionData["question_no"];
    const test_id = this.state.testId;
    //GET latest Test Data
    $.ajax({
      type: "GET",
      url: getBaseURL() + "/test/preview?test_id=" + test_id, //Hard Coded TODO
      dataType: "JSON",
    }).done((data) => {
      if (data && data["questions"] && data["questions"].length > 0) {
        this.setState({
          currentQuestionData:
            data["questions"][parseInt(currentQuestionNumber - 1)],
          currentQuestionNumber: parseInt(currentQuestionNumber),
        });
      } else {
        //TODO handle no data case
      }
    });
  };

  handleTestModeChange = (e) => {
    e.preventDefault();
    var searchParamsJson = qs.parse(this.props.location.search);
    searchParamsJson.mode = e.currentTarget.name;
    if (searchParamsJson.mode != "view") {
      const testInfoData = this.state.testInfoData;
      if (testInfoData.status == "COMPLETED") {
        if (confirm("You are about to edit a completed test. Are you sure?")) {
          this.handleEditCompletedTest();
          this.setState({ isEditMode: true });
        }
      } else {
        this.setState({ isEditMode: true });
      }
    } else {
      this.setState({ isEditMode: false });
    }

    var searchQuery = qs.stringify(searchParamsJson);
    this.props.history.push({ search: searchQuery });
  };

  handleEditCompletedTest() {
    $.ajax({
      type: "POST",
      url: getBaseURL() + "/edit-completed-test",
      data: { test_info_id: this.state.testId },
    })
      .done((response, status, jqXHR) => {
        var testInfoData = this.state.testInfoData;
        if (testInfoData.status != "INCOMPLETE") {
          testInfoData.status = "INCOMPLETE";
          this.setState({ testInfoData });
        }
        sessionStorage.removeItem("testSearchResults");
        return;
      })
      .fail((jqXHR, textStatus, error) => {
        alert(jqXHR["responseText"]);
        return;
      });
  }

  handleSubmitTest = (e) => {
    $.ajax({
      type: "POST",
      url: getBaseURL() + "/submit-test",
      data: { test_info_id: this.state.testId },
    })
      .done((response, status, jqXHR) => {
        sessionStorage.removeItem("testSearchResults");
        this.props.history.push("/manage-tests");
      })
      .fail((jqXHR, textStatus, error) => {
        alert(jqXHR["responseText"]);
        return;
      });
  };

  handleReviewComplete = (e) => {
    $.ajax({
      type: "POST",
      url: getBaseURL() + "/approve-test",
      data: { test_info_id: this.state.testId },
    })
      .done((response, status, jqXHR) => {
        sessionStorage.removeItem("testSearchResults");
        this.props.history.push("/manage-tests");
      })
      .fail((jqXHR, textStatus, error) => {
        alert(jqXHR["responseText"]);
        return;
      });
  };
  /*
   handleTestPrint = (e) => {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
   }*/

  // handleTestPrint = (e) => {
  //    const input = document.getElementById('divToPrint');

  //    html2canvas(input,{scale: "5"})
  //      .then((canvas) => {
  //        const imgData = canvas;
  //        const pdf = new jsPDF("portrait","mm", "a4");
  //        pdf.addImage(imgData, 'JPEG', 5, 5, 200, 285);
  //        //pdf.addImage(imgData, 0, 0, 210, 297);
  //        // pdf.output('dataurlnewwindow');
  //        pdf.save("download.pdf");
  //      })
  //    ;
  //   }

  //  handleTestPrint = (e) => {
  //
  //  const input = document.getElementById('divToPrint');
  //  var opt = {
  //    margin:       1,
  //    filename:     'myfile.pdf',
  //    image:        { type: 'jpeg', quality: 0.98 },
  //    html2canvas:  { scale: 5 },
  //    jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', scale: 5 }
  //  };
  //  html2pdf().from(input).set(opt).save();
  // }

  //  handleTestPrint = (e) => {
  //
  //  const pages = [...document.querySelectorAll('#divToPrint')];
  //  //const input = document.getElementById('divToPrint');
  //  var opt = {
  //    margin:       1,
  //    filename:     'myfile.pdf',
  //    image:        { type: 'jpeg', quality: 0.98 },
  //    html2canvas:  { scale: 5 },
  //    jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', scale: 5 },
  //    pagebreak:    { mode: 'avoid-all', before: '#page2el' }
  //  };

  //  var worker = html2pdf().set(opt).from(pages[0]).toPdf();
  //  pages.slice(1).forEach(function (page) {
  //      worker = worker.get('pdf').then(function (pdf) {
  //          pdf.addPage();
  //      }).from(page).toContainer().toCanvas().set(opt).toPdf();
  //  });
  //  worker = worker.save();
  // //html2pdf().from(input).set(opt).save();
  // }

  handleTestPrint = (e) => {
    e.preventDefault();

    var testInfoData = this.state.testInfoData;
    if (testInfoData.status != "COMPLETED") {
      alert("You can only print completed tests.");
      return;
    }

    var printContents = document.getElementById("windowPrint").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    location.reload();
    //document.body.innerHTML = originalContents;
    //this.props.history.replace(this.props.location);
    //document.body.innerHTML = originalContents;
  };

  handleTestViewModeChange = (e) => {
    var mode = e.currentTarget.name;
    this.setState({ testViewMode: mode });
  };

  handleFetchQuestion = (e) => {
    var fetchSourceTestData = this.state.fetchSourceTestData;
    var fetchQuestionsSource = this.state.fetchQuestionsSource;
    var component = e.currentTarget.name;
    var qNumber = component.split("_")[1];

    var currentQuestionData = this.state.currentQuestionData;

    currentQuestionData = this.handleBaseFetch(
      fetchSourceTestData,
      fetchQuestionsSource,
      qNumber,
      currentQuestionData
    );
    //var fetchSourceTestData = this.state.testData;
    this.setState({ currentQuestionData });
    this.handleSaveQuestionData(e);
  };

  recordFetchQuestionHistory = (datasetQId, testName) => {
    if (datasetQId && testName) {
      $.ajax({
        type: "POST",
        url: getBaseURL() + "/repo/fetch-event.json",
        data: { datasetQId: datasetQId, testName: testName },
      })
        .done((data) => {
          console.log(data);
        })
        .fail((jqXHR, textStatus, error) => {
          if (jqXHR["responseJSON"] && jqXHR["responseJSON"].message) {
            alert(jqXHR["responseJSON"].message);
          } else {
            alert(jqXHR["responseText"]);
          }
          return;
        });
    }
  };

  handleFetchMetareadQuestion = (e) => {
    var comp_name = e.currentTarget.name;
    var fragmentIndex = comp_name.split("_")[1];
    var projectFragments = this.state.projectFragments;
    var currentQuestionData = this.state.currentQuestionData;
    currentQuestionData["question_content"]["question"] =
      projectFragments[fragmentIndex]["content"]["question"];
    if (
      currentQuestionData["question_content"]["options"] &&
      currentQuestionData["question_content"]["options"].length > 0
    ) {
      currentQuestionData["question_content"]["options"].forEach((qOption) => {
        var matchingOpt = projectFragments[fragmentIndex]["content"][
          "options"
        ].filter((fragmentOpt) => fragmentOpt["label"] == qOption["label"]);
        if (matchingOpt && matchingOpt.length > 0) {
          qOption["content"] = matchingOpt[0]["content"];
        }
      });
    }
    currentQuestionData["question_content"]["key"] =
      projectFragments[fragmentIndex]["content"]["key"];
    currentQuestionData["question_content"]["solution"] =
      projectFragments[fragmentIndex]["content"]["solution"];
    this.setState({ currentQuestionData });
    this.handleSaveQuestionData(e);
  };

  handleFetchAllMetaread = (e) => {
    //data["question"]
    var testData = this.state.testData;
    var projectFragments = this.state.projectFragments;
    if (projectFragments.length == testData["questions"].length) {
      testData["questions"].forEach(
        (currentQuestionData, fragmentIndex, array) => {
          currentQuestionData["question_content"]["question"] =
            projectFragments[fragmentIndex]["content"]["question"];
          if (
            currentQuestionData["question_content"]["options"] &&
            currentQuestionData["question_content"]["options"].length > 0
          ) {
            currentQuestionData["question_content"]["options"].forEach(
              (qOption) => {
                var matchingOpt = projectFragments[fragmentIndex]["content"][
                  "options"
                ].filter(
                  (fragmentOpt) => fragmentOpt["label"] == qOption["label"]
                );
                if (matchingOpt && matchingOpt.length > 0) {
                  qOption["content"] = matchingOpt[0]["content"];
                }
              }
            );
          }
          currentQuestionData["question_content"]["key"] =
            projectFragments[fragmentIndex]["content"]["key"];
          currentQuestionData["question_content"]["solution"] =
            projectFragments[fragmentIndex]["content"]["solution"];
          this.handleExplicitSave(currentQuestionData);
        }
      );
      this.setState({
        testData: testData,
        currentQuestionData: testData["questions"][0],
      });
    } else {
      alert(
        "Fetch All Questions Failed. The number of questions in test and metaread project do not match"
      );
      return;
    }
  };

  initFetchSourceTestLovData(programId, testTypeId) {
    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/search-tests.json?limit=30&test_type_id=" +
        testTypeId +
        "&global_program_id=" +
        programId,
    })
      .done((data, status, jqXHR) => {
        var fetchSourceTestSelectList = data.map((test) => ({
          label: test.name,
          value: test.id,
        }));
        this.setState({ fetchSourceTestSelectList });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        /* var errorMessages = this.state.errorMessages;
      errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
        this.setState({errorMessages: errorMessages});*/
      });
  }

  handleFetchSourceTestNameLovChange = (selectedTestNameObj, actionMeta) => {
    var fetchSourceTestId = selectedTestNameObj.value;
    if (fetchSourceTestId) {
      $.ajax({
        type: "GET",
        url: getBaseURL() + "/test/preview?test_id=" + fetchSourceTestId, //Hard Coded TODO
        dataType: "JSON",
      }).done((data) => {
        if (data && data["questions"] && data["questions"].length > 0) {
          this.setState({ fetchSourceTestData: data });
          this.filterFetchSourceTestData();
        } else {
          this.setState({
            fetchSourceTestData: {},
            filteredFetchSourceTestData: {},
          });
        }
      });
    }
  };

  handleFetchSourceTestAdvSearch = (e) => {
    const fetchSourceTestAdvSearchFormData =
      this.state.fetchSourceTestAdvSearchFormData;
    var testName = fetchSourceTestAdvSearchFormData["testName"];
    var testNumber = fetchSourceTestAdvSearchFormData["testNumber"];
    const testInfoData = this.state.testInfoData;
    const programId = testInfoData["global_program_id"];
    const testTypeId = testInfoData["global_test_type_id"];

    if (!testName) {
      testName = "";
    }
    if (!testNumber) {
      testNumber = "";
    }

    $.ajax({
      type: "GET",
      url:
        getBaseURL() +
        "/search-tests.json?limit=30&name=" +
        testName +
        "&identifier=" +
        testNumber +
        "&test_type_id=" +
        testTypeId +
        "&global_program_id=" +
        programId,
    })
      .done((data, status, jqXHR) => {
        this.populateFetchSourceTestAdvSearchResults(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        /* var errorMessages = this.state.errorMessages;
      errorMessages.push({message: "An Unexpected Error occured while searching for Test Model."})
        this.setState({errorMessages: errorMessages});*/
      });
  };

  populateFetchSourceTestAdvSearchResults(data) {
    var fetchSourceTestAdvSearchResults = {};
    var testAdvSearchColumns = [
      {
        name: "testId",
        options: {
          display: false,
        },
      },
      { name: "Name" },
      {
        name: "label",
        options: {
          display: false,
        },
      },
      { name: "Identifier" },
      { name: "Description" },
    ];
    fetchSourceTestAdvSearchResults["columns"] = testAdvSearchColumns;

    var testAdvSearchData = [];
    if (data && data.length > 0) {
      for (var i in data) {
        testAdvSearchData.push([
          data[i].id,
          data[i].name,
          data[i].name,
          data[i].identifier,
          data[i].description,
        ]);
      }
      fetchSourceTestAdvSearchResults["data"] = testAdvSearchData;
      this.setState({ fetchSourceTestAdvSearchResults });
    } else {
      this.setState({ fetchSourceTestAdvSearchResults });
    }
  }

  handleOnChangeFetchSourceTestAdvSearchForm = (e) => {
    e.preventDefault();
    var targetName = e.target.name;
    var targetNameComp = targetName.split("_");
    var attr = targetNameComp[0];
    var fetchSourceTestAdvSearchFormData =
      this.state.fetchSourceTestAdvSearchFormData;
    fetchSourceTestAdvSearchFormData[attr] = e.target.value;
    this.setState({ fetchSourceTestAdvSearchFormData });
  };

  filterFetchSourceTestData(qNumber) {
    if (this.state.isFetchQuestionsView) {
      var currentQuestionData = {};
      if (qNumber) {
        var testData = this.state.testData;
        currentQuestionData = testData["questions"][qNumber - 1];
      } else {
        currentQuestionData = this.state.currentQuestionData;
      }
      var fetchSourceTestData = this.state.fetchSourceTestData;

      var filteredFetchSourceTestData = {};
      if (
        fetchSourceTestData &&
        fetchSourceTestData["questions"] &&
        fetchSourceTestData["questions"].length > 0
      ) {
        filteredFetchSourceTestData["questions"] = fetchSourceTestData[
          "questions"
        ].filter(
          (question) =>
            question.global_subject_id ==
              currentQuestionData.global_subject_id &&
            question.pattern_short_name ==
              currentQuestionData.pattern_short_name
        );
      }
      this.setState({ filteredFetchSourceTestData });
    }
  }

  toggleFetchQuestionsView = (e, source) => {
    var isFetchQuestionsView = this.state.isFetchQuestionsView;
    if (!isFetchQuestionsView && source == "tests") {
      var data = this.state.testData;
      if (
        data["test_details"]["global_program_id"] &&
        data["test_details"]["global_test_type_id"]
      ) {
        this.initFetchSourceTestLovData(
          data["test_details"]["global_program_id"],
          data["test_details"]["global_test_type_id"]
        );
      }
    }
    this.setState({
      isFetchQuestionsView: !isFetchQuestionsView,
      fetchQuestionsSource: source,
    });
  };

  handleBaseFetch(
    fetchSourceTestData,
    fetchQuestionsSource,
    qNumber,
    currentQuestionData
  ) {
    //var fetchSourceTestData = this.state.testData;
    if (fetchQuestionsSource == "dataset") {
      var filteredSourceTestData = fetchSourceTestData["questions"].filter(
        (question) => question.question_no == qNumber
      );
      var fetchSourceTestQuestionData = filteredSourceTestData[0];
    } else if (fetchQuestionsSource == "tests") {
      var fetchSourceTestQuestionData =
        fetchSourceTestData["questions"][parseInt(qNumber) - 1];
    }

    if (fetchQuestionsSource != "dataset") {
      if (
        currentQuestionData["subject_short_name"] == "ZOO" ||
        currentQuestionData["subject_short_name"] == "BOT"
      ) {
        if (
          fetchSourceTestQuestionData["subject_short_name"] != "ZOO" &&
          fetchSourceTestQuestionData["subject_short_name"] != "BOT"
        ) {
          alert(
            "Fetch Question Failed. The question Subject is different from original question."
          );
          return;
        }
      } else if (
        currentQuestionData["subject_short_name"] !=
        fetchSourceTestQuestionData["subject_short_name"]
      ) {
        alert(
          "Fetch Question Failed. The question Subject is different from original question."
        );
        return;
      }
      if (
        currentQuestionData["pattern_short_name"] !=
        fetchSourceTestQuestionData["pattern_short_name"]
      ) {
        alert(
          "Fetch Question Failed. The question Pattern is different from original question."
        );
        return;
      }
    }
    if (fetchQuestionsSource == "tests") {
      if (
        currentQuestionData["positive_marks"] !=
          fetchSourceTestQuestionData["positive_marks"] ||
        currentQuestionData["negative_marks"] !=
          fetchSourceTestQuestionData["negative_marks"] ||
        currentQuestionData["partial_marks"] !=
          fetchSourceTestQuestionData["partial_marks"]
      ) {
        alert(
          "Fetch Question Failed. The Marking Schema of question is different from original question."
        );
        return;
      }
    }
    currentQuestionData["question_content"] =
      fetchSourceTestQuestionData["question_content"];
    if (fetchQuestionsSource == "tests") {
      currentQuestionData["key"] = fetchSourceTestQuestionData["key"];
    } else if (fetchQuestionsSource == "dataset") {
      currentQuestionData["key"] = JSON.stringify(
        fetchSourceTestQuestionData["key"]
      );
      currentQuestionData["question_source"] = "dataset";
      currentQuestionData["question_source_id"] = "";

      // add extra data
      currentQuestionData["question_content"]["repo_question_id"] =
        fetchSourceTestQuestionData?._id?.$oid;
      let tagsData = {};
      let tagsList = [
        "Program",
        "Course",
        "Subject",
        "Chapter",
        "Topic",
        "Concept",
        "Pattern",
        "Level",
        "Source",
        "Others",
      ];
      tagsList.forEach((tag) => {
        if (fetchSourceTestQuestionData[tag?.toLowerCase()]) {
          tagsData[tag?.toLowerCase()] =
            fetchSourceTestQuestionData[tag?.toLowerCase()];
        }
      });
      currentQuestionData["question_content"]["tags"] = tagsData;
    }
    currentQuestionData["solution"] = fetchSourceTestQuestionData["solution"];
    //To handle multi-component math solution
    if (
      currentQuestionData["solution"].includes("\\begin") &&
      currentQuestionData["solution"].includes("\\end")
    ) {
      var solJson = JSON.parse(currentQuestionData["solution"]);
      var fIndex = solJson.findIndex((a) => a[0].includes("\\begin"));
      var lIndex = solJson.findIndex((a) => a[0].includes("\\end"));
      var tblContent = solJson.slice(fIndex, lIndex + 1);
      var filterTblContent = tblContent.filter((a) => a[1] == "");
      if (tblContent.length == filterTblContent.length) {
        var combinedTextContent = filterTblContent.map((c) => c[0]).join(" ");
        var initSolElems = [];
        var lastSolElems = [];
        if (fIndex > 0) {
          initSolElems = solJson.slice(0, fIndex);
        }
        if (solJson.length > lIndex + 1) {
          lastSolElems = solJson.slice(lIndex + 1, solJson.length);
        }
        var newSolJson = [
          ...initSolElems,
          [combinedTextContent, ""],
          ...lastSolElems,
        ];
        currentQuestionData["solution"] = JSON.stringify(newSolJson);
      }
    }
    if (fetchQuestionsSource == "dataset") {
      //record fetch questions
      let testName = this.state?.testData?.test_details?.name;
      this.recordFetchQuestionHistory(
        fetchSourceTestQuestionData?._id?.$oid,
        testName
      );
      //recored fetched questions
      let fetchedQuestionsSourceIds = this.state.fetchedQuestionsSourceIds;
      let repo_question_id =
        currentQuestionData?.question_content?.repo_question_id;
      if (repo_question_id) {
        fetchedQuestionsSourceIds.push(repo_question_id);
        this.setState({ fetchedQuestionsSourceIds });
      }
    }
    return currentQuestionData;
    // this.setState({ currentQuestionData });
    // this.handleSaveQuestionData(e);
    //filter as well
    //then fetch into questions
  }

  getDatasetQuestionsCallback = (data, isAutoFetchMode) => {
    if (data && data["questions"] && data["questions"].length > 0) {
      var fetchSourceTestData = data;
      this.setState({ fetchSourceTestData });
      if (isAutoFetchMode) {
        var fetchQuestionsSource = "dataset";

        var testData = this.state.testData;
        var currentQuestionData = this.state.currentQuestionData;
        var currQuestionNumber = currentQuestionData?.question_no;
        let totalQuestions = testData?.questions?.length;

        for (let i = 0; i < fetchSourceTestData?.questions?.length; i++) {
          //validations
          if (i + 1 > totalQuestions) {
            break;
          }
          //Check matching subjects & question pattern type
          //Same logic to apply in handle Fetch Question - to not allow random content to go in
          //Same logic to apply inside filterFetchSourceTestData - to show only relevant question

          var qNumber = i + 1;
          currentQuestionData = this.handleBaseFetch(
            fetchSourceTestData,
            fetchQuestionsSource,
            qNumber,
            currentQuestionData
          );
          this.handleExplicitSave(currentQuestionData);

          if (i + 1 != fetchSourceTestData?.questions?.length) {
            currQuestionNumber = currQuestionNumber + 1;
            currentQuestionData =
              testData["questions"][parseInt(currQuestionNumber) - 1];
          }
        }
        this.setState({ currentQuestionData });
        this.navToQuestionNumber(currQuestionNumber);
      } else {
        this.filterFetchSourceTestData();
      }
    }
  };

  getProjects(type) {
    $.ajax({
      type: "GET",
      url: "/metaread_projects.json?type=" + type + "&limit=20",
    })
      .done((data, status, jqXHR) => {
        this.populateProjectResults(data);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorsReported = this.state.errorsReported;
        errorsReported.push({
          message: "An Unexpected Error occured while searching for Test.",
        });
        this.setState({ errorsReported: errorsReported });
      });
  }

  populateProjectResults(data) {
    var projectsInfo = data["projects"];
    var projectSearchResults = [];
    if (projectsInfo && projectsInfo.length > 0) {
      for (var i in projectsInfo) {
        projectSearchResults.push({
          id: projectsInfo[i].id,
          name: projectsInfo[i].name,
        });
      }
      this.setState({ projectSearchResults });
    } else {
      this.setState({ projectSearchResults: [] });
    }
  }

  onProjectIdChange = (e) => {
    var projectId = e.target.value;
    this.setState({ projectId });
    this.getProjectFragments(projectId);
  };

  getProjectFragments(projectId) {
    $.ajax({
      type: "GET",
      url:
        "/metaread_project_contents.json?project_id=" + projectId + "&limit=50",
    })
      .done((data, status, jqXHR) => {
        this.setState({ projectFragments: data["projectFragments"] });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        var errorsReported = this.state.errorsReported;
        errorsReported.push({
          message:
            "An unexpected error occured while fetching project fragments.",
        });
        this.setState({ errorsReported: errorsReported });
      });
  }

  initializeQuestionSourceIds(questions) {
    if (questions?.length > 0) {
      let fetchedQuestionsSourceIds = [];
      questions.forEach((question) => {
        if (question?.question_content?.repo_question_id) {
          fetchedQuestionsSourceIds.push(
            question?.question_content?.repo_question_id
          );
        }
      });
      this.setState({ fetchedQuestionsSourceIds });
    }
  }

  render() {
    /* const { classes} = this.props;*/
    const { projectFragments } = this.state;
    return (
      <div>
        <GridContainer style={{ margin: "auto" }} spacing={16}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ padding: "8px", paddingTop: "0px" }}
          >
            <div style={{ height: "auto", backgroundColor: "white" }}>
              <TestHeader
                testInfoData={this.state.testInfoData}
                handleSubmitTest={this.handleSubmitTest}
                handleReviewComplete={this.handleReviewComplete}
                handleTestViewModeChange={this.handleTestViewModeChange}
                testViewMode={this.state.testViewMode}
                toggleFetchQuestionsView={this.toggleFetchQuestionsView}
                isFetchQuestionsView={this.state.isFetchQuestionsView}
                isApprover={this.state.isApprover}
                {...this.props}
              />
            </div>
          </GridItem>
          {this.state.testViewMode == "grid" && (
            <React.Fragment>
              {this.state.isFetchQuestionsView &&
                this.state.fetchQuestionsSource == "tests" &&
                this.state.testData &&
                this.state.testData["questions"] && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ paddingLeft: "8px", paddingRight: "0px" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        backgroundColor: "white",
                        height: "90vh",
                        overflowY: "scroll",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "400",
                          fontFamily: "serif",
                          color: "rgb(60, 72, 88)",
                          fontSize: "16px",
                          lineHeight: "1.55em",
                          margin: "10px",
                        }}
                      >
                        Fetch Questions Overview
                      </h5>
                      <div
                        style={{
                          minWidth: "130px",
                          padding: "10px",
                          paddingTop: "10px",
                          margin: "auto",
                          width: "80%",
                        }}
                      >
                        <InputLabel
                          shrink
                          htmlFor="material13"
                          style={{ padding: "0px", paddingBottom: "5px" }}
                        >
                          Select Test to Fetch Questions
                        </InputLabel>
                        <AutoSuggestSelect
                          name="fetchSourceTest_name"
                          options={this.state.fetchSourceTestSelectList}
                          //value={value}
                          formControlWidth="60%"
                          onValueChange={
                            this.handleFetchSourceTestNameLovChange
                          }
                          advSearchExecuteCallback={
                            this.handleFetchSourceTestAdvSearch
                          }
                          advSearchFormComponent={
                            <TestAdvSearchForm
                              fetchSourceTestAdvSearchFormData={
                                this.state.fetchSourceTestAdvSearchFormData
                              }
                              handleOnChangeTestAdvSearchForm={
                                this.handleOnChangeFetchSourceTestAdvSearchForm
                              }
                            />
                          }
                          advSearchResults={
                            this.state.fetchSourceTestAdvSearchResults
                          }
                          isComponentDisabled={false}
                        />
                      </div>

                      <TestQuestionsContainerListView
                        completeTestData={
                          this.state.filteredFetchSourceTestData
                        }
                        handleTestPrint={this.handleTestPrint}
                        isFetchQuestionsView={this.state.isFetchQuestionsView}
                        handleFetchQuestion={this.handleFetchQuestion}
                        {...this.props}
                      />
                    </div>
                  </GridItem>
                )}
              {this.state.isFetchQuestionsView &&
                this.state.fetchQuestionsSource == "dataset" &&
                this.state.testData &&
                this.state.testData["questions"] && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ paddingLeft: "8px", paddingRight: "0px" }}
                  >
                    <DatasetMain
                      hideTestHeader={true}
                      isEmbeddedMode={true}
                      defaultTestInfo={{
                        subject:
                          this.state.currentQuestionData["subject_short_name"],
                      }}
                      isFetchQuestionsView={this.state.isFetchQuestionsView}
                      handleFetchQuestion={this.handleFetchQuestion}
                      getDatasetQuestionsCallback={
                        this.getDatasetQuestionsCallback
                      }
                      fetchedQuestionsSourceIds={
                        this.state.fetchedQuestionsSourceIds
                      }
                      {...this.props}
                    />
                  </GridItem>
                )}
              {this.state.isFetchQuestionsView &&
                this.state.fetchQuestionsSource == "metaread" && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ paddingRight: "0px" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        backgroundColor: "white",
                        height: "90vh",
                        overflowY: "scroll",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "400",
                          fontFamily: "serif",
                          color: "rgb(60, 72, 88)",
                          fontSize: "16px",
                          lineHeight: "1.55em",
                          margin: "16px auto",
                          width: "fit-content",
                        }}
                      >
                        Fetch Questions | MetaRead Store
                      </h5>
                      <div style={{ width: "50%", margin: "auto" }}>
                        <div style={{ marginBottom: "15px" }}>
                          <FormControl fullWidth>
                            <InputLabel id="mr_type">Type</InputLabel>
                            <Select
                              id="mr_type"
                              value={this.state.mr_type}
                              name="mr_type"
                              onChange={(event) => {
                                this.setState({
                                  mr_type: event.target.value,
                                  projectId: "",
                                });
                                this.getProjects(event.target.value);
                              }}
                            >
                              <MenuItem value="QP">
                                Question Paper (PDF)
                              </MenuItem>
                              <MenuItem value="SINGLE">
                                Questions (Image)
                              </MenuItem>
                              <MenuItem value="DOUBTS">Text</MenuItem>
                              {/*<MenuItem value="BOOK">Book</MenuItem>*/}
                            </Select>
                          </FormControl>
                        </div>
                        <div style={{ marginBottom: "15px" }}>
                          <FormControl fullWidth>
                            <InputLabel id="projectId">Project</InputLabel>
                            <Select
                              id="projectId"
                              style={{ textTransform: "capitalize" }}
                              value={this.state.projectId}
                              name="projectId"
                              onChange={this.onProjectIdChange}
                            >
                              {this.state.projectSearchResults.map(
                                (project, index) => (
                                  <MenuItem
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={project.id}
                                  >
                                    {project.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        {projectFragments && projectFragments.length > 0 && (
                          <div style={{ marginBottom: "15px" }}>
                            <Button
                              color="primary"
                              //name={"fetch_"+ index}
                              onClick={this.handleFetchAllMetaread}
                              style={{
                                color: "white",
                                backgroundColor: "#3f51b5",
                                marginLeft: "auto",
                                display: "block",
                                marginRight: "2%",
                                marginBottom: "8px",
                              }}
                              size="small"
                            >
                              Fetch All
                            </Button>
                          </div>
                        )}
                      </div>
                      <div>
                        {projectFragments && projectFragments.length > 0 && (
                          <MetaReadDataListView
                            handleFetch={this.handleFetchMetareadQuestion}
                            projectFragments={projectFragments}
                          />
                        )}
                      </div>
                    </div>
                  </GridItem>
                )}
              <GridItem
                xs={12}
                sm={12}
                md={this.state.isFetchQuestionsView ? 5 : 9}
                style={{ paddingRight: "0px", paddingLeft: "8px" }}
              >
                <div style={{ height: "100%", backgroundColor: "white" }}>
                  <TestQuestionsContainer
                    testData={this.state.currentQuestionData}
                    nextQ={this.navNextQuestion}
                    prevQ={this.navPrevQuestion}
                    lastQ={this.state.lastQ}
                    firstQ={this.state.firstQ}
                    isEditMode={this.state.isEditMode}
                    handleEditTestData={this.handleEditTestData}
                    handleSaveQuestionData={this.handleSaveQuestionData}
                    handleDiscardQuestionData={this.handleDiscardQuestionData}
                    handleClearQuestionData={this.handleClearQuestionData}
                    questionSavedIndicator={this.state.questionSavedIndicator}
                    handleAddTextComponent={this.handleAddTextComponent}
                    handleAddListComponent={this.handleAddListComponent}
                    handleRemoveListComponent={this.handleRemoveListComponent}
                    handleRemoveTextComponent={this.handleRemoveTextComponent}
                    handlePasteEvent={this.handlePasteEvent}
                    handleRemoveImageComponent={this.handleRemoveImageComponent}
                    handleTestModeChange={this.handleTestModeChange.bind(this)}
                    isTestScheduled={this.state.isTestScheduled}
                    isFetchQuestionsView={this.state.isFetchQuestionsView}
                    handleImageFileUpload={this.handleImageFileUpload}
                    handleClearKey={this.handleClearKey}
                    {...this.props}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} style={{ padding: "0px 8px" }}>
                <div style={{ height: "auto", backgroundColor: "white" }}>
                  <TestNavigator
                    data={this.state.navTestData}
                    navToQ={this.navToQuestion}
                    currentQuestionNumber={this.state.currentQuestionNumber}
                    navfocusSubjectIndex={this.state.navfocusSubjectIndex}
                    handleNavfocusSubject={this.handleNavfocusSubject}
                    {...this.props}
                  />
                </div>
              </GridItem>
            </React.Fragment>
          )}
          {this.state.testViewMode == "list" && (
            <React.Fragment>
              <GridItem
                xs={12}
                sm={12}
                md={2}
                style={{ padding: "0px 8px" }}
              ></GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ padding: "0px 8px" }}>
                <div style={{ height: "100%", backgroundColor: "white" }}>
                  <TestQuestionsContainerListView
                    completeTestData={this.state.testData}
                    handleTestPrint={this.handleTestPrint.bind(this)}
                    navTestData={this.state.navTestData}
                    {...this.props}
                  />
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={2}
                style={{ padding: "0px 8px" }}
              ></GridItem>
            </React.Fragment>
          )}
        </GridContainer>
      </div>
    );
  }
}

export default TestRegion;
